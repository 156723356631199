import React from 'react'
import { format } from 'date-fns'
import { countryNameByIso2 } from 'dataSource/countries'

const SavedDetailsByType = ({ shareholder }) => {
  const SpecificDetails = () => {
    switch (shareholder.type) {
      case 'Individual':
        return (
          <div>
            {shareholder?.firstName} {shareholder?.lastName}
            {shareholder?.dob && (
              <div>
                Dob{' '}
                <span className="text-gold-400">
                  {format(new Date(shareholder?.dob), 'dd/MM/yyyy')}
                </span>
              </div>
            )}
            {shareholder?.passportNumber && (
              <div>
                Passport/ID Number{' '}
                <span className="text-gold-400">
                  {shareholder?.passportNumber}
                </span>
              </div>
            )}
          </div>
        )

      case 'Family':
        return (
          <div>
            {shareholder?.firstName} {shareholder?.lastName}
            {shareholder?.dob && (
              <div>
                Dob{' '}
                <span className="text-gold-400">
                  {format(new Date(shareholder?.dob), 'dd/MM/yyyy')}
                </span>
              </div>
            )}
            {shareholder?.passportNumber && (
              <div>
                Passport/ID Number{' '}
                <span className="text-gold-400">
                  {shareholder?.passportNumber}
                </span>
              </div>
            )}
          </div>
        )

      case 'Corporate':
        return (
          <div>
            {shareholder?.businessName}
            {shareholder?.regDate && (
              <div>
                Registered Date{' '}
                <span className="text-gold-400">
                  {format(new Date(shareholder?.regDate), 'dd/MM/yyyy')}
                </span>
              </div>
            )}
            {shareholder?.filingNumber && (
              <div>
                Filing Number{' '}
                <span className="text-gold-400">
                  {shareholder?.filingNumber}
                </span>
              </div>
            )}
          </div>
        )
      default:
        return null
    }
  }

  return (
    <div>
      <SpecificDetails />
      {shareholder?.email && (
        <div>
          Email <span className="text-gold-400">{shareholder?.email}</span>
        </div>
      )}
      {shareholder?.mobile && (
        <div>
          Mobile <span className="text-gold-400">{shareholder?.mobile}</span>
        </div>
      )}
      <p className="text-sm">
        Share <span className="text-gold-400">{shareholder.share}%</span>
      </p>
      <div className="mt-2 text-muted">
        <h5 className="text-white">Address</h5>
        {shareholder.address}
        {shareholder.city} - {shareholder.zipCode}
        <p>
          {shareholder.state}, {countryNameByIso2(shareholder.country)}
        </p>
      </div>
    </div>
  )
}

export default SavedDetailsByType

import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useShareholder } from 'backend/useShareholder'
import EntityFormIndividual from 'Components/EntityFormIndividual'
import EntityFormCorporate from 'Components/EntityFormCorporate'
import EntityFormFamily from 'Components/EntityFormFamily'

const ShareholderEdit = () => {
  const navigate = useNavigate()
  const { id } = useParams()
  const [formData, setFormData] = useState({})
  const { updateShareholder, getShareholder } = useShareholder()

  useEffect(() => {
    getShareholder(id).then((item) => setFormData(item))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id])

  const onSubmit = (data) => {
    console.log(data)
    updateShareholder({ id: id, ...data }).then((id) => {
      navigate('/shareholders')
    })
  }

  switch (formData.type) {
    case 'Individual':
      return (
        <EntityFormIndividual
          data={formData}
          onSubmit={onSubmit}
          action="edit"
          title="Individual Shareholder Details"
        />
      )
    case 'Corporate':
      return (
        <EntityFormCorporate
          data={formData}
          onSubmit={onSubmit}
          action="edit"
          title="Corporate Shareholder Details"
        />
      )
    case 'Family':
      return (
        <EntityFormFamily
          data={formData}
          onSubmit={onSubmit}
          action="edit"
          title="Family Shareholder Details"
        />
      )
    default:
      return <></>
    // return <>No shareholder type selected</>
  }
}

export default ShareholderEdit

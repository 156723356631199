import cx from 'classnames'
import { useFormContext } from 'react-hook-form'

export const hasErrors = (obj) => {
  // null and undefined are "empty"
  if (obj == null) return false

  // Assume if it has a length property with a non-zero value
  // that that property is correct.
  if (obj.length > 0) return true
  if (obj.length === 0) return false

  // If it isn't an object at this point
  // it is empty, but it can't be anything *but* empty
  // Is it empty?  Depends on your application.
  if (typeof obj !== 'object') return false

  // Otherwise, does it have any properties of its own?
  // Note that this doesn't handle
  // toString and valueOf enumeration bugs in IE < 9
  // eslint-disable-next-line no-restricted-syntax
  for (const key in obj) {
    if (hasOwnProperty.call(obj, key)) return true
  }
  return false
}

export const preventSubmit = (
  preventResubmit,
  isSubmitted,
  isSubmitting,
  errors
) => {
  if (isSubmitting || (preventResubmit && isSubmitted)) {
    return true
  }
  return hasErrors(errors)
}

const Submit = ({
  className = '',
  children,
  activeTitle = '',
  preventResubmit = false,
}) => {
  const {
    formState: { isSubmitted, isSubmitting, errors },
  } = useFormContext() // retrieve all hook methods
  return (
    <button
      className={cx(className)}
      disabled={preventSubmit(
        preventResubmit,
        isSubmitted,
        isSubmitting,
        errors
      )}
      type="submit"
    >
      {activeTitle
        ? isSubmitting || isSubmitted
          ? activeTitle
          : children
        : children}
    </button>
  )
}

// const Button = {
//   Text,
//   Email,
//   Password,
//   TextArea
// }
export default Submit

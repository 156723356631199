import React, { Fragment, useState } from 'react'
import { Link } from 'react-router-dom'
import InfiniteScroll from 'react-infinite-scroller'
import { FaFileInvoiceDollar } from 'react-icons/fa'
import { HiCog, HiRefresh } from 'react-icons/hi'
import { Form, Input, Submit } from 'tui'
import { usePrice } from 'ECommerce/hooks/usePrice'
import { LoaderTextOnly } from 'utils/loader'
import Placeholder from 'Components/Placeholder'
import { usePaginate } from 'backend/usePaginate'

const endpoint = 'order/my-bills'
const UpcomingBills = () => {
  const [filtered, setFiltered] = useState(false)
  const { price } = usePrice()
  const { hasMore, items, loadMore, filter, loading } = usePaginate(endpoint, {
    limit: 50,
  })

  const onSubmit = (data) => {
    filter({ ...data, businessName: data.businessName + '%' }).then(() =>
      setFiltered(true)
    )
    console.log('data', data)
  }

  const clearFilter = () => {
    filter({}).then(() => setFiltered(false))
  }

  return (
    <div>
      <h1 className="text-3xl mb-4">Upcoming Bills</h1>
      <Form onFinish={onSubmit}>
        <div className="flex justify-between items-start">
          <div className="flex space-x-4">
            <div>
              <Input.Text
                name="businessName"
                placeholder="Search by Company Name"
              />
            </div>
            {/* <div>
              <Select
                name="billType"
                placeholder="Select a bill type"
                options={[
                  { value: "Registered Agent", label: "Registered Agent" },
                  { value: "BT", label: "BT" },
                ]}
              />
            </div> */}
          </div>
          <div className="flex items-center">
            <Submit className="bg-primary flex items-center p-2 px-4 mr-2">
              <HiCog className="mr-2" /> Apply Filter
            </Submit>
            {filtered && (
              <button
                type="reset"
                className="flex items-center bg-input p-2 px-4"
                onClick={clearFilter}
              >
                <HiRefresh className="mr-2" />
                Clear Filter
              </button>
            )}
          </div>
        </div>
      </Form>
      <div className="text-center">
        <InfiniteScroll
          pageStart={0}
          initialLoad={false}
          loadMore={loadMore}
          hasMore={hasMore}
          loader={
            <h4 style={{ textAlign: 'center' }} key="loader">
              {/* Loading... */}
              <LoaderTextOnly />
            </h4>
          }
        >
          <>
            {loading === false && items?.length === 0 ? (
              <Placeholder
                icon={FaFileInvoiceDollar}
                text={
                  filtered
                    ? 'No result found'
                    : 'Upcoming bills will display here'
                }
              />
            ) : (
              <>
                <div className="hidden md:grid grid-cols-2 md:grid-cols-5 mt-5 text-gold-400 font-semibold  p-4">
                  <div>Company Name</div>
                  <div className="hidden md:block">Bill Type</div>
                  <div className="hidden md:block">Amount</div>
                  <div className="hidden md:block">Due Date</div>
                  <div className="text-right">View</div>
                </div>
                {items.map((item) => (
                  <Fragment key={item.id}>
                    <div className="bg-black rounded-md">
                      <div className="grid grid-cols-1 md:grid-cols-5 mt-5 items-center  p-4">
                        <div className="py-2 text-left">
                          <span>{item.companyName}</span>
                          <div className="md:hidden text-muted">
                            {item.billtype}
                          </div>
                          <div className="md:hidden text-gold-500">
                            {price(item.amount, item.currency)}
                          </div>
                          <div className="md:hidden text-muted">
                            {item.dueDate}
                          </div>
                        </div>
                        <div className="hidden md:block text-muted">
                          {item.billtype}
                        </div>
                        <div className="hidden md:block text-gold-500">
                          {item.amount}
                        </div>
                        <div className="hidden md:block text-muted">
                          {item.dueDate}
                        </div>
                        <div className="text-right">
                          <Link to="/">View Bill</Link>
                        </div>
                      </div>
                    </div>
                  </Fragment>
                ))}
              </>
            )}
          </>
        </InfiniteScroll>
      </div>
    </div>
  )
}

export default UpcomingBills

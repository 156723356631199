import React, { useState } from 'react'
import { useDispatch } from 'react-redux'

import { Form, Select, Submit } from 'tui'
import ReusableModal from 'Components/ReusableModal'
import { useBusiness } from 'backend/useBusiness'
import { shareholderTypes } from 'dataSource/options'
import { uiActions } from 'features/uiSlice'
import { businessTypes } from 'dataSource/constants'
import ExpiredBlock from 'Components/ExpiredBlock'

const ShareholderModalSelect = ({ business, id }) => {
  const dispatch = useDispatch()
  const [selectedShareholderType, setSelectedShareholderType] = useState(
    business.shareholderType
  )
  const { updateBusinessDetails } = useBusiness()
  const isLegacy = business.businessType === businessTypes.legacy

  const handleChangeShareholderType = (value) => {
    if (selectedShareholderType !== value) {
      setSelectedShareholderType(value)
    }
  }

  const handleClose = () => {
    dispatch(uiActions.closeReUsableModal())
  }

  const onSubmit = (data) => {
    console.log(data)
    let payload = {
      id: id,
      shareholderType: data.shareholderType,
      shareholderCount: data?.shareholderCount || 1,
    }

    const msg = { loading: 'Updating...', success: 'Shareholder Type Updated.' }
    updateBusinessDetails(payload, msg).then(() => {
      handleClose()
    })
  }

  const shareholderTypeOptions = () => {
    switch (business.businessType) {
      case 'LEGACY':
        return shareholderTypes.slice(1)
      default:
        return shareholderTypes
    }
  }

  const getShareholderOptions = () => {
    let count = 0
    switch (selectedShareholderType) {
      case 'Individual':
        count = 7
        break
      case 'Corporate':
        count = 5
        break
      default:
        count = 2
    }
    if (isLegacy) {
      count = 5
    }
    return Array(count)
      .fill(1)
      .map((a, i) => ({ value: i + 1, label: String(i + 1) }))
  }

  const getTitle = () => {
    if (business?.blocked) {
      return 'Your business is blocked!'
    } else if (business?.expired) {
      return 'Your business is expired!'
    }
    return business.businessType === businessTypes.legacy
      ? 'Select Number of Trustee'
      : 'Select Shareholder Type'
  }

  return (
    <ReusableModal title={getTitle()}>
      {/* <pre>
        {JSON.stringify(
          {
            shareholderCount: business.shareholderCount,
            shareholderType: selectedShareholderType,
          },
          null,
          2
        )}
      </pre> */}
      {business?.expired || business?.blocked ? (
        <ExpiredBlock business={business} handleClose={handleClose} />
      ) : (
        <Form
          onFinish={onSubmit}
          initialValues={{
            shareholderCount: business.shareholderCount,
            shareholderType: selectedShareholderType,
          }}
        >
          <div className="grid grid-cols-2 gap-5 mt-8">
            <div className="w-full col-span-2">
              {!isLegacy && (
                <Select
                  required
                  name="shareholderType"
                  label={'Shareholder Type'}
                  placeholder="Select Type"
                  options={shareholderTypeOptions()}
                  rules={{
                    required: 'Shareholder Type is required',
                  }}
                  onSelect={handleChangeShareholderType}
                />
              )}
            </div>
            <div className="w-full col-span-2">
              <Select
                required
                name="shareholderCount"
                label={isLegacy ? 'Number of Trustee' : 'Number of Shareholder'}
                placeholder={
                  isLegacy
                    ? 'Select Number of Trustee'
                    : 'Select Number of Shareholder'
                }
                options={getShareholderOptions()}
                rules={{
                  required: isLegacy
                    ? 'Number of Trustee is required'
                    : 'Number of Shareholders is required',
                }}
              />
            </div>
          </div>
          <div className="flex justify-end mt-5">
            {/* <button
            type="button"
            className="mr-2 btn btn-secondary"
            onClick={handleClose}
            ref={cancelButtonRef}
          >
            Close
          </button> */}
            <Submit className="btn btn-primary">Submit</Submit>
          </div>
        </Form>
      )}
    </ReusableModal>
  )
}

export default ShareholderModalSelect

import { createSlice } from '@reduxjs/toolkit'
import { status } from './status'

const initialState = {
  shareholder: {
    status: status.loading,
    items: [],
  },
}

const uniqueItems = (items) => {
  var lookup = []
  var result = []

  for (const item of items) {
    var id = item?.id

    if (!id) {
      return
    }
    if (!lookup.includes(id)) {
      lookup.push(id)
      result.push(item)
    }
  }
  return result
}

export const shareholderSlice = createSlice({
  name: 'shareholder',
  initialState,
  reducers: {
    loading(state) {
      state.shareholder.status = status.loading
    },
    error(state) {
      state.shareholder.status = status.idle
    },
    loadIds(state, action) {
      state.shareholder.ids = action.payload.ids || []
    },
    loaded(state, action) {
      // state.shareholder = {
      //   items: action.payload.items || [],
      //   status: action.payload.status,
      // };
      // state.shareholder.items =
      // action.payload.items.filter((item) => item.businessType !== 'CORP') ||
      // []
      state.shareholder.items = action.payload.items
      state.shareholder.status = action.payload.status
    },
    add(state, action) {
      state.shareholder.items.push(action.payload.item)
    },
    append(state, action) {
      const items = state.shareholder.items.concat(action.payload.item)
      state.shareholder.items = uniqueItems(items)
    },
    update(state, action) {
      state.shareholder.items = state.shareholder.items.map((item) => {
        if (item.id === action.payload.item.id) {
          return { ...item, ...action.payload.item }
        }
        return item
      })
    },
    deleteById: (state, { payload }) => {
      state.shareholder.items = state.shareholder.items.filter(
        (cur) => cur.id !== payload.id
      )
    },
    clear() {
      return { ...initialState }
    },
  },
})

export const shareholderActions = shareholderSlice.actions
export default shareholderSlice.reducer

export const selectShareholder = (state) => state.shareholder

import { useDispatch } from 'react-redux'
import { useBackend } from './useBackend'
import { orderActions } from 'features/ordersSlice'

export function useOrder() {
  const { backend } = useBackend()
  const dispatch = useDispatch()

  const makeOrder = (payload) =>
    new Promise((resolve) => {
      let config = {
        loader: true,
        msg: false,
      }
      backend()
        .post(`/order`, payload, config)
        .then(({ item }) => {
          dispatch(orderActions.ordersAdd({ item }))
          return resolve(item)
        })
        .catch((e) => console.log(e))
    })

  const makeAgentOrder = (payload) =>
    new Promise((resolve) => {
      let config = {
        loader: true,
        msg: { loading: 'Processing.' },
      }
      backend()
        .post(`/order/agent`, payload, config)
        .then(({ item }) => {
          dispatch(orderActions.ordersAdd({ item }))
          return resolve(item)
        })
        .catch((e) => console.log(e))
    })

  const makeBusinessOrder = (payload) =>
    new Promise((resolve) => {
      let config = {
        loader: true,
        msg: false,
      }
      backend()
        .post(`/order/business`, payload, config)
        .then(({ item }) => {
          dispatch(orderActions.ordersAdd({ item }))
          return resolve(item)
        })
        .catch((e) => console.log(e))
    })

  // const makeAdminBusinessOrder = (payload) =>
  //   new Promise((resolve) => {
  //     let config = {
  //       loader: true,
  //       msg: { loading: 'Making Payment...', success: 'Payment Success.' },
  //     }
  //     backend()
  //       .post(`/order/business-admin`, payload, config)
  //       .then(({ item }) => {
  //         dispatch(orderActions.ordersAdd({ item: item }))
  //         return resolve(item)
  //       })
  //   })

  const orderGoodStanding = (payload) =>
    new Promise((resolve) => {
      let config = {
        loader: true,
        msg: false,
      }
      backend()
        .post('/order/good-standing', payload, config)
        .then(({ item }) => {
          dispatch(orderActions.ordersAdd({ item }))
          return resolve(item)
        })
        .catch((e) => console.log(e))
    })

  const makeAdminOrderGoodStanding = (payload) =>
    new Promise((resolve) => {
      let config = {
        loader: true,
        msg: false,
      }
      backend()
        .post('/order/good-standing-admin', payload, config)
        .then(({ item }) => {
          dispatch(orderActions.ordersAdd({ item }))
          return resolve(item)
        })
        .catch((e) => console.log(e))
    })

  const stripeCreateCheckoutSession = (payload) =>
    new Promise((resolve) => {
      let config = {
        loader: true,
        msg: false,
      }
      payload.domainURL = window.location.origin
      backend()
        .post(`/stripe/create-checkout-session`, payload, config)
        .then(({ data }) => {
          return resolve(data)
        })
        .catch((e) => console.log(e))
    })

  const coinbaseCreateCharge = (payload) =>
    new Promise((resolve) => {
      let config = {
        loader: true,
        msg: false,
      }
      payload.domainURL = window.location.origin
      backend()
        .post(`/coinbase/create-charge`, payload, config)
        .then(({ data }) => {
          return resolve(data)
        })
        .catch((e) => console.log(e))
    })

  const cancelForumPay = (orderId) =>
    new Promise((resolve) => {
      let config = {
        loader: true,
        msg: false,
      }
      backend()
        .put(`/forumpay/cancel/${orderId}`, {}, config)
        .then(({ data }) => {
          return resolve(data)
        })
        .catch((e) => console.log(e))
    })

  const myOrders = (payload) =>
    new Promise((resolve) => {
      let config = {
        loader: false,
        msg: false,
      }
      const query = new URLSearchParams(payload).toString()
      dispatch(orderActions.ordersLoading())

      backend()
        .get(`/order/my-orders?${query}`, config)
        .then((data) => {
          dispatch(
            orderActions.orders({
              items: data.items || [],
            })
          )
          return resolve(data)
        })
        .catch((err) => {
          dispatch(orderActions.ordersError())
          console.log(err)
        })
    })

  const getPaymentsByBusiness = ({ id }) =>
    new Promise((resolve) => {
      let config = {
        loader: false,
        msg: false,
      }
      const query = new URLSearchParams({ limit: 100 }).toString()
      dispatch(orderActions.ordersByBusinessLoading())
      backend()
        .get(`/order/business/${id}?${query}`, config)
        .then((data) => {
          dispatch(
            orderActions.ordersByBusiness({
              items: data.items || [],
            })
          )
          return resolve(data)
        })
        .catch((err) => {
          console.log(err)
          dispatch(orderActions.ordersByBusinessError())
        })
    })

  const myUpcomingBills = (payload) =>
    new Promise((resolve) => {
      let config = {
        loader: false,
        msg: false,
      }
      dispatch(orderActions.upcomingBillsLoading())
      // const params = new URLSearchParams(JSON.stringify(payload)).toString()
      const query = new URLSearchParams(payload).toString()
      backend()
        .get(`/order/my-bills?${query}`, config)
        .then((data) => {
          dispatch(
            orderActions.upcomingBills({
              items: data.items,
            })
          )
          return resolve(data)
        })
        .catch((err) => {
          console.log(err)
          setTimeout(() => {
            dispatch(orderActions.upcomingBillsError())
          }, 1000)
        })
    })

  const storeGoodStanding = (payload) =>
    new Promise((resolve) => {
      let config = {
        loader: true,
        msg: { loading: 'Processing...' },
      }
      backend()
        .post('/good-standing', payload, config)
        .then(({ item }) => {
          dispatch(orderActions.goodStanding({ item }))
          return resolve(item)
        })
        .catch((e) => console.log(e))
    })

  const getOrderById = (id) =>
    new Promise((resolve) => {
      let config = {
        loader: false,
        msg: false,
      }
      dispatch(orderActions.currentOrderLoading())
      backend()
        .get(`/order/${id}`, config)
        .then(({ item }) => {
          dispatch(orderActions.currentOrder({ item }))
          return resolve(item)
        })
        .catch((err) => {
          console.log(err)
          dispatch(orderActions.currentOrderError())
        })
    })

  return {
    makeOrder,
    makeAgentOrder,
    makeBusinessOrder,
    // makeAdminBusinessOrder,
    myOrders,
    myUpcomingBills,
    getPaymentsByBusiness,
    stripeCreateCheckoutSession,
    coinbaseCreateCharge,
    cancelForumPay,
    storeGoodStanding,
    orderGoodStanding,
    makeAdminOrderGoodStanding,
    getOrderById,
  }
}

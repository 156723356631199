import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { FaEdit } from 'react-icons/fa'

import Placeholder from 'Components/Placeholder'
import DateTimeAgo from 'Components/DateTimeAgo'
import { selectBusiness } from 'features/businessSlice'
import { status } from 'features/status'
import { useBusiness } from 'backend/useBusiness'
import { LoaderTextBlock } from 'utils/loader'
import { getLabel } from 'utils/label'
// import Badge from "tui/Components/Badge"

const NameAddendumHistory = () => {
  const { id } = useParams()
  const { myBusinessByAddendum } = useBusiness()
  const { addendum } = useSelector(selectBusiness)

  useEffect(() => {
    const payload = {
      businessId: id,
    }
    myBusinessByAddendum(payload)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div>
      <div>
        <h2 className="text-2xl mb-3">Addendum History</h2>
      </div>
      <div className="mt-2">
        {/* <pre>{JSON.stringify(addendum.items, null, 2)}</pre> */}
        <div>
          {addendum.status === status.loading ? (
            <LoaderTextBlock />
          ) : addendum?.items?.length === 0 ? (
            <Placeholder
              icon={FaEdit}
              text="Addendum History will display here"
            />
          ) : (
            addendum.items.map((item) => (
              <div className="bg-black my-4 rounded-md p-2" key={item.id}>
                <div className="font-semibold m-2">
                  <div className="grid grid-cols-10 gap-2">
                    <div className="col-span-8">
                      <div className="text-gold-400">
                        {item.oldFullName} ⟶ {item.newFullName}
                        <small className="pl-2 text-muted text-xs">
                          {getLabel(item.status)}
                        </small>
                      </div>
                      <p className="mb-1 text-muted">{item.title}</p>
                    </div>
                    <div className="col-span-2 text-right">
                      <p className="text-muted mb-2">
                        <DateTimeAgo date={item.createdAt} />
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            ))
          )}
        </div>
      </div>
    </div>
  )
}

export default NameAddendumHistory

import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import {
  selectShareholder,
  shareholderActions,
} from 'features/shareholderSlice'
import { useBackend } from './useBackend'
import { status } from 'features/status'
import { registeredAs } from 'config'
import { paginationAction } from 'features/pagination/paginationSlice'

export function useShareholder() {
  const { shareholder, shareholderOptions } = useSelector(selectShareholder)
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { backend } = useBackend()

  /**
   * Call only first time of registration process.
   * It will create a new business or update.
   * @param {*} payload
   * @returns
   */
  const createShareholder = (payload, config) =>
    new Promise((resolve) => {
      // let config = {
      //   loader: true,
      //   msg: { loading: 'Saving Shareholder...', success: 'Shareholder saved' },
      // }
      backend()
        .post('/shareholder-business', payload, config)
        .then(({ item }) => {
          dispatch(
            shareholderActions.add({
              item: item,
              status: status.idle,
            })
          )
          return resolve(item.id)
        })
    })

  const updateShareholder = (
    payload,
    msg = { loading: 'Updating...', success: 'Updated.' },
    loader = true
  ) =>
    new Promise((resolve) => {
      let config = { loader, msg }
      const { id, ...body } = payload
      backend()
        .put(`/shareholder-business/${id}`, body, config)
        .then(({ item }) => {
          dispatch(
            shareholderActions.update({
              item: item,
              status: status.idle,
            })
          )
          return resolve(item.id)
        })
    })

  const searchShareholder = (payload) =>
    new Promise((resolve) => {
      let config = {
        loader: true,
        msg: false,
      }
      const query = new URLSearchParams({ ...payload, registeredAs }).toString()
      backend()
        .get(`/shareholder-business/search?${query}`, config)
        .then((data) => {
          return resolve(data)
        })
        .catch((err) => {
          console.log(err)
        })
    })

  const myShareholders = (payload) =>
    new Promise((resolve) => {
      let config = {
        loader: false,
        msg: false,
      }
      // const query = new URLSearchParams(payload).toString()
      const query = new URLSearchParams({ ...payload }).toString()
      dispatch(shareholderActions.loading())
      backend()
        .get(`/shareholder-business/my-shareholders?${query}`, config)
        .then((data) => {
          dispatch(
            shareholderActions.loaded({
              items: data.items,
              status: status.idle,
            })
          )
          return resolve(data)
        })
        .catch((err) => {
          console.log(err)
          dispatch(shareholderActions.error())
        })
    })

  const getShareholder = (id, redirect = true) =>
    new Promise((resolve) => {
      let config = { loader: true, msg: null }
      backend()
        .get(`/shareholder-business/${id}`, config)
        .then(({ item }) => {
          // dispatch(shareholderActions.current({ item: item }))
          dispatch(shareholderActions.append({ item: item }))
          return resolve(item)
        })
        .catch((error) => {
          if (redirect) {
            navigate(`/`)
          }
        })
    })

  const getShareholderOptionsByType = (type) => {
    return shareholder.items
      .filter((item) => item.type === type)
      .map((item) => {
        return {
          label: `${item.firstName} ${item.lastName} ${item.businessName} - ${item.email}`,
          value: item.id,
        }
      })
  }

  const onSelectShareholder = (id) => {
    return shareholder.items.filter((item) => item.id === id)
  }

  const deleteShareholder = (id) =>
    new Promise((resolve) => {
      let config = {
        loader: true,
        msg: { loading: 'Processing...', success: 'Shareholder deleted' },
      }
      backend()
        .delete(`/shareholder-business/${id}`, config)
        .then(({ item }) => {
          dispatch(paginationAction.deleteById({ id }))
          dispatch(shareholderActions.deleteById({ id }))
          return resolve(item)
        })
        .catch((error) => {})
    })

  return {
    shareholder,
    shareholderOptions,
    updateShareholder,
    createShareholder,
    myShareholders,
    searchShareholder,
    getShareholder,
    deleteShareholder,
    getShareholderOptionsByType,
    onSelectShareholder,
  }
}

import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import dayjs from 'dayjs'
import { FaDownload, FaFileInvoiceDollar } from 'react-icons/fa'
import Placeholder from 'Components/Placeholder'
import { useCertificate } from 'backend/useCertificate'
import { LoaderBlock } from 'utils/loader'
import { useBusiness } from 'backend/useBusiness'
import {
  businessTypes,
  paymentStatus,
  shareholderType,
} from 'dataSource/constants'
import { getChineseSign, getWesternSign } from 'utils/zodiac'
import { getParameterByName } from 'utils/browser'

const action = getParameterByName('action')

const Certificates = () => {
  const { id } = useParams()
  const [currentBusiness, setCurrentBusiness] = useState({})

  const { certificates } = useCertificate()
  const { getBusiness } = useBusiness()

  useEffect(() => {
    getBusiness(id).then((item) => {
      let beneficiaries = item.beneficiaries
      if (item.businessType === businessTypes.legacy) {
        beneficiaries = beneficiaries.map((b, i) => {
          const d = dayjs(b.dob)
          const year = d.format('YYYY')
          const month = d.format('MM')
          const day = d.format('DD')
          const westernSign = getWesternSign(Number(day), Number(month))
          const chineseSign = getChineseSign(Number(year))
          return { ...b, westernSign, chineseSign }
        })
      }
      setCurrentBusiness({ ...item, beneficiaries })
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id])

  return (
    <div>
      <div>
        <h2 className="text-2xl mb-5">Download Certificates</h2>
      </div>
      <div className="bg-black p-4 rounded-lg">
        {/* <pre>{JSON.stringify(currentBusiness, null, 2)}</pre> */}
        {currentBusiness?.status !== paymentStatus.Confirmed ? (
          <div className="text-center">
            <Placeholder
              icon={FaFileInvoiceDollar}
              text="Please complete the payment to download certificates"
            />
          </div>
        ) : certificates?.status === 'loading' ? (
          <LoaderBlock />
        ) : certificates?.items?.length === 0 ? (
          <Placeholder
            icon={FaDownload}
            text="Your certificates will display here"
          />
        ) : (
          <div>
            {['ALL', currentBusiness.entityEnding].map((entityEnding) => (
              <CertificateListRegistry
                certificates={certificates}
                business={currentBusiness}
                type="Registry"
                entityEnding={entityEnding}
              />
            ))}
            {['ALL', currentBusiness.entityEnding].map((entityEnding) => (
              <CertificateListZodiac
                certificates={certificates}
                business={currentBusiness}
                type="Zodiac"
                entityEnding={entityEnding}
              />
            ))}
            {['ALL', currentBusiness.entityEnding].map((entityEnding) => (
              <CertificateListNameAddendum
                certificates={certificates}
                business={currentBusiness}
                type="NameAddendum"
                entityEnding={entityEnding}
              />
            ))}
            {['ALL', currentBusiness.entityEnding].map((entityEnding) => (
              <CertificateListShareholderAddendum
                certificates={certificates}
                business={currentBusiness}
                type="ShareholderAddendum"
                entityEnding={entityEnding}
              />
            ))}
          </div>
        )}
      </div>
    </div>
  )
}

export default Certificates

const CertificateListRegistry = ({
  certificates,
  business,
  type,
  entityEnding,
}) => {
  const c = certificates.items.filter((cert) => cert.type === type)
  return (
    <>
      {c
        .filter(
          (cert) =>
            cert.category.includes(business.shareholderType) &&
            cert.businessType === business.businessType &&
            cert.entityEnding === entityEnding
        )
        .map((cert, i) => (
          <div key={i}>
            {cert.shareholderCount === 0 ? (
              <DownloadLink businessId={business.id} item={cert} />
            ) : cert.shareholderCount === 1 &&
              business.shareholderCount === 1 ? (
              <DownloadLink businessId={business.id} item={cert} />
            ) : (
              cert.shareholderCount === 2 &&
              business.shareholderCount >= 2 && (
                <DownloadLink businessId={business.id} item={cert} />
              )
            )}
          </div>
        ))}
    </>
  )
}

const CertificateListZodiac = ({
  certificates,
  business,
  type,
  entityEnding,
}) => {
  const c = certificates.items.filter((cert) => cert.type === type)
  return (
    <>
      {c
        .filter(
          (cert) =>
            cert.category.includes(business.shareholderType) &&
            cert.businessType === business.businessType &&
            cert.entityEnding === entityEnding
        )
        .map((cert, i) => (
          <div key={i}>
            {cert.shareholderCount === 0 ? (
              <>
                {business.beneficiaries.map(
                  (b, i) =>
                    cert.zodiacSystem === 'Western' &&
                    cert.zodiacSign === b.westernSign && (
                      <DownloadBirthCertificateLink
                        businessId={business.id}
                        item={cert}
                        beneficiary={b}
                        i={i + 1}
                      />
                    )
                )}
                {business.beneficiaries.map(
                  (b, i) =>
                    cert.zodiacSystem === 'Chinese' &&
                    cert.zodiacSign === b.chineseSign && (
                      <DownloadBirthCertificateLink
                        businessId={business.id}
                        item={cert}
                        beneficiary={b}
                        i={i + 1}
                      />
                    )
                )}
              </>
            ) : cert.shareholderCount === 0 ? (
              <DownloadLink businessId={business.id} item={cert} />
            ) : cert.shareholderCount === 1 &&
              business.shareholderCount === 1 ? (
              <DownloadLink businessId={business.id} item={cert} />
            ) : (
              cert.shareholderCount === 2 &&
              business.shareholderCount >= 2 && (
                <DownloadLink businessId={business.id} item={cert} />
              )
            )}
          </div>
        ))}
    </>
  )
}

const CertificateListNameAddendum = ({
  certificates,
  business,
  type,
  entityEnding,
}) => {
  if (!business?.businessAddendum) {
    return null
  }
  if (business?.businessAddendum.length === 0) {
    return null
  }
  const addendum = business.businessAddendum.filter(
    (item) => item.status === 'Confirmed'
  )
  if (addendum.length === 0) {
    return null
  }
  const c = certificates.items.filter((cert) => cert.type === type)
  return (
    <>
      {c
        .filter(
          (cert) =>
            cert.category.includes(business.shareholderType) &&
            cert.businessType === business.businessType &&
            cert.entityEnding === entityEnding
        )
        .map((cert, i) => (
          <div key={i}>
            {cert.shareholderCount === 0 ? (
              <DownloadLink businessId={business.id} item={cert} />
            ) : cert.shareholderCount === 1 &&
              business.shareholderCount === 1 ? (
              <DownloadLink businessId={business.id} item={cert} />
            ) : (
              cert.shareholderCount === 2 &&
              business.shareholderCount >= 2 && (
                <DownloadLink businessId={business.id} item={cert} />
              )
            )}
          </div>
        ))}
    </>
  )
}

const CertificateListShareholderAddendum = ({
  certificates,
  business,
  type,
  entityEnding,
}) => {
  if (business?.shareholderType === shareholderType.Corporate) {
    if (business?.corporateShareholders.length === 1) {
      return null
    }
  }
  if (business?.shareholderType === shareholderType.Individual) {
    if (business?.shareholders.length <= 2) {
      return null
    }
  }
  const c = certificates.items.filter((cert) => cert.type === type)
  return (
    <>
      {c
        .filter(
          (cert) =>
            cert.category.includes(business.shareholderType) &&
            cert.businessType === business.businessType &&
            cert.entityEnding === entityEnding
        )
        .map((cert, i) => (
          <div key={i}>
            {cert.shareholderCount === 0 ? (
              <DownloadLink businessId={business.id} item={cert} />
            ) : cert.shareholderCount === 1 &&
              business.shareholderCount === 1 ? (
              <DownloadLink businessId={business.id} item={cert} />
            ) : (
              cert.shareholderCount === 2 &&
              business.shareholderCount >= 2 && (
                <DownloadLink businessId={business.id} item={cert} />
              )
            )}
          </div>
        ))}
    </>
  )
}

const DownloadLink = ({ item, businessId }) => {
  const { getCertificateUrl } = useCertificate()
  const params = {
    // type: 'docx',
    action: action || 'download',
  }
  return item?.status === 'Active' ? (
    <a
      href={getCertificateUrl(businessId, item.id, params)}
      className="secondary w-full my-1 block"
      target="_blank"
      rel="noreferrer"
    >
      {item.title}
      {/* <span className="text-xs text-muted">{item.shareholderCount} {item.category.join(' ')}</span> */}
    </a>
  ) : (
    <span>{item.title}</span>
  )
}

const DownloadBirthCertificateLink = ({ item, businessId, beneficiary, i }) => {
  const { getCertificateUrl } = useCertificate()
  const params = {
    // type: 'docx',
    action: action || 'download',
    b: i, // this for beneficiary index
  }
  return item?.status === 'Active' ? (
    <a
      href={getCertificateUrl(businessId, item.id, params)}
      className="secondary w-full my-1 block"
      target="_blank"
      rel="noreferrer"
    >
      {item.title} FOR BENEFICIARY {i}{' '}
      {String(beneficiary.firstName).toUpperCase()}
    </a>
  ) : (
    <span>{item.title}</span>
  )
}

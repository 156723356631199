import { lazy } from 'react'
import PageLayout from 'Layouts/PageLayout'
import { Web3ModalProvider } from 'utils/walletConnect'
import AuthGuard from './AuthGuard'
import SingleLayout from 'Layouts/SingleLayout'
import AssetDetailsLayout from 'Pages/Assets/AssetDetailsLayout'
import AssetInfo from 'Pages/Assets/AssetDetails'

const RealWorldAssets = lazy(() => import('Pages/Assets/Step1Intro'))
const AssetDetails = lazy(() => import('Pages/Assets/Step3Details'))
const AssetCategories = lazy(() => import('Pages/Assets/Step2Categories'))
const AssetSubCategories = lazy(() => import('Pages/Assets/Step4Documents'))
const AssetDetailsConfirmation = lazy(
  () => import('Pages/Assets/Step5ConfirmDetails')
)

const AssetRoutes = {
  path: '/',
  children: [
    {
      path: 'asset',
      element: (
        <AuthGuard>
          <PageLayout />
        </AuthGuard>
      ),
      children: [
        {
          path: '',
          element: <RealWorldAssets />,
        },
        {
          path: 'details',
          element: (
            <Web3ModalProvider>
              <AssetDetails />
            </Web3ModalProvider>
          ),
        },
        {
          path: 'categories',
          element: <AssetCategories />,
        },
        {
          path: 'documents',
          element: <AssetSubCategories />,
        },
        {
          path: 'confirm-details',
          element: <AssetDetailsConfirmation />,
        },
      ],
    },
    {
      path: 'assets',
      element: (
        <AuthGuard>
          <SingleLayout />
        </AuthGuard>
      ),
      children: [
        {
          path: ':id',
          element: <AssetDetailsLayout />,
          children: [
            {
              index: true,
              element: <AssetInfo />,
            },
          ],
        },
      ],
    },
  ],
}

export default AssetRoutes

import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useBeneficiary } from 'backend/useBeneficiary'
import EntityFormFamily from 'Components/EntityFormFamily'
import EntityFormIndividual from 'Components/EntityFormIndividual'
import EntityFormCorporate from 'Components/EntityFormCorporate'

const BeneficiaryEdit = () => {
  const navigate = useNavigate()
  const { id } = useParams()
  const [formData, setFormData] = useState({})
  const { updateBeneficiary, getBeneficiary } = useBeneficiary()

  useEffect(() => {
    getBeneficiary(id).then((item) => setFormData(item))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id])

  const onSubmit = (data) => {
    console.log(data)
    updateBeneficiary({ id: id, ...data }).then((id) => {
      navigate('/beneficiaries')
    })
  }

  switch (formData.type) {
    case 'Individual':
      return (
        <EntityFormIndividual
          data={formData}
          onSubmit={onSubmit}
          action="edit"
          title="Individual Beneficiary Details"
        />
      )
    case 'Corporate':
      return (
        <EntityFormCorporate
          data={formData}
          onSubmit={onSubmit}
          action="edit"
          title="Corporate Beneficiary Details"
        />
      )
    case 'Family':
      return (
        <EntityFormFamily
          data={formData}
          onSubmit={onSubmit}
          action="edit"
          title="Family Beneficiary Details"
        />
      )
    default:
      return <></>
    // return <>No shareholder type selected</>
  }
}

export default BeneficiaryEdit

import React, { useEffect, useState } from 'react'
import { Outlet, useNavigate, useParams } from 'react-router-dom'
import { useAsset } from 'backend/useAsset'

const AssetDetailsLayout = () => {
  const navigate = useNavigate()
  const { id } = useParams()
  const [formData, setFormData] = useState({})

  const { getSpecificAsset } = useAsset()

  useEffect(() => {
    getSpecificAsset(id).then((item) => setFormData(item))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id])

  return (
    <div className="flex flex-col justify-center">
      <div className="mb-4">
        <h1 className="text-3xl mb-2">
          {formData?.businessFullName && (
            <span>{formData?.businessFullName}</span>
          )}
        </h1>
        <div className="text-xs">
          <div className="text-muted mb-2">{formData?.bicraFormatted}</div>
        </div>
      </div>
      <div className="flex gap-8 mt-5 justify-center">
        <div className="block col-span-full md:col-span-9  justify-center">
          <Outlet formData={formData} />
        </div>
      </div>
      <div className="flex justify-between mt-5">
        <button
          type="button"
          className="back"
          tabIndex="-1"
          onClick={() => navigate(-1)}
        >
          Back
        </button>
      </div>
    </div>
  )
}

export default AssetDetailsLayout

import React, { Fragment, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { FaTimes, FaUsers } from 'react-icons/fa'
import { Dialog, Transition } from '@headlessui/react'
import { selectShareholder } from 'features/shareholderSlice'
import { selectBeneficiary } from 'features/beneficiarySlice'
import SavedDetailsByType from './SavedDetailsByType'

const ShareholderSelectModal = ({ onSelectShareholder, type, formName }) => {
  const [open, setOpen] = useState(false)
  const { shareholder } = useSelector(selectShareholder)
  const { beneficiary } = useSelector(selectBeneficiary)

  const handleClose = () => {
    setOpen(false)
  }

  const onSelect = (data) => {
    if (data.label) {
      delete data.label
    }
    onSelectShareholder(data)
    handleClose()
  }

  // const isBeneficiary = formName === 'beneficiary'

  const formFiltered = useMemo(() => {
    const shareholders = (shareholder?.items || [])
      .filter((item) => item.type === type)
      .map((item) => ({ ...item, label: 'Shareholder' }))
    const beneficiaries = (beneficiary?.items || [])
      .filter((item) => item.type === type)
      .map((item) => ({ ...item, label: 'Beneficiary' }))
    return shareholders.concat(beneficiaries)
  }, [shareholder, beneficiary, type])

  return (
    <>
      <div className="text-right">
        <button
          type="button"
          onClick={() => setOpen(true)}
          className="text-gold-400 p-0"
        >
          Use Saved {type} Details
          {/* Use Saved {isBeneficiary ? 'Beneficiary' : 'Shareholder'} */}
        </button>
      </div>
      <Transition.Root show={open} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={handleClose}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-dark-800 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed z-10 inset-0 overflow-y-auto">
            <div className="flex items-end sm:items-center justify-center min-h-full p-4 text-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative bg-black rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:max-w-4xl sm:w-full md:w-1/4">
                  <div className="px-0 pt-5 pb-4 sm:p-6 md:p-2 sm:pb-4">
                    <div className="sm:flex sm:items-start">
                      <div className="mt-3 text-center sm:mt-0 sm:mx-4 sm:text-left mb-4 w-full relative">
                        <div className="absolute top-4 right-0">
                          <FaTimes
                            className="text-muted cursor-pointer hover:text-white"
                            onClick={handleClose}
                          />
                        </div>
                        <Dialog.Title
                          as="h3"
                          className="text-xl leading-6 font-medium py-2"
                        >
                          Select {type} User Details
                          {/* {isBeneficiary ? 'Beneficiary' : 'Shareholder'} */}
                        </Dialog.Title>

                        <div>
                          {!formFiltered.length > 0 && (
                            <div className="flex flex-col items-center my-10">
                              <FaUsers className="text-7xl text-gold-400" />
                              <small>
                                No {type} Users Found
                                {/* {isBeneficiary ? 'Beneficiary' : 'Shareholder'} */}
                              </small>
                            </div>
                          )}
                          {formFiltered.length > 0 && (
                            <small className="text-muted">
                              Click on {type} to Select Details
                              {/* {isBeneficiary ? 'Beneficiary' : 'Shareholder'} to select */}
                            </small>
                          )}
                          {formFiltered.length > 0 &&
                            formFiltered.map((item) => (
                              <div
                                className="bg-dark-900 border-2 my-4 rounded-md p-4 w-full border-black hover:border-gold-400 cursor-pointer"
                                key={item.id}
                                onClick={() => onSelect(item)}
                              >
                                <div>
                                  <p className="text-gold-400">
                                    {/* {isBeneficiary
                                      ? 'Beneficiary'
                                      : 'Shareholder'}{' '} */}
                                    {item?.type} {item?.label} Details
                                  </p>
                                  <SavedDetailsByType shareholder={item} />
                                </div>
                              </div>
                            ))}
                        </div>
                      </div>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  )
}

export default ShareholderSelectModal

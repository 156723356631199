import { createSlice } from '@reduxjs/toolkit'
import { status } from './status'

const initialState = {
  business: {
    status: status.loading,
    items: [],
  },
  address: {
    status: status.loading,
    items: [],
  },
  addendum: {
    status: status.loading,
    items: [],
  },
  current: {},
  formData: {},
  registerAmount: 99,
  renewAmount: 49,
}

const uniqueItems = (items) => {
  var lookup = []
  var result = []

  for (const item of items) {
    var id = item?.id

    if (!id) {
      return
    }
    if (!lookup.includes(id)) {
      lookup.push(id)
      result.push(item)
    }
  }
  return result
}

export const businessSlice = createSlice({
  name: 'business',
  initialState,
  reducers: {
    loading(state) {
      state.business.status = status.loading
    },
    error(state) {
      state.business.status = status.idle
    },
    clearFormData(state) {
      state.formData = {}
    },
    loadIds(state, action) {
      state.business.ids = action.payload.ids || []
    },
    loaded(state, action) {
      // state.business = {
      //   items: action.payload.items || [],
      //   status: action.payload.status,
      // };
      // state.business.items =
      // action.payload.items.filter((item) => item.businessType !== 'CORP') ||
      // []
      state.business.items = action.payload.items
      state.business.status = action.payload.status
    },
    add(state, action) {
      state.business.items.push(action.payload.item)
    },
    append(state, action) {
      const items = state.business.items.concat(action.payload.item)
      state.business.items = uniqueItems(items)
      state.business.status = status.fulfilled
    },
    // current(state, action) {
    //   state.current = action.payload.item
    // },
    // clearCurrent(state) {
    //   state.current = {}
    // },
    update(state, { payload }) {
      state.business.items = state.business.items.map((item) => {
        if (item.id === payload.item.id) {
          return { ...item, ...payload.item }
        }
        return item
      })
    },
    businessStatus(state, { payload }) {
      const items = state.business.items.map((item) => {
        if (payload.ids.find((id) => id === item.id)) {
          return { ...item, status: payload.status }
        }
        return item
      })
      state.business.items = items
    },
    addendumLoading(state) {
      state.addendum.status = status.loading
    },
    addendumLoad(state, action) {
      state.addendum.items = action.payload.items
      state.addendum.status = action.payload.status
    },
    addendumError(state) {
      state.addendum.status = status.rejected
    },
    clear() {
      return { ...initialState }
    },
  },
})

export const businessActions = businessSlice.actions
export default businessSlice.reducer

export const selectBusiness = (state) => state.business
// export const selectBusiness = (id) => (state => state.business.items.find((item) => item.id === id))

import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { usePrice } from 'ECommerce/hooks/usePrice'
import { useOrder } from 'backend/useOrder'
import { orderTypes } from 'dataSource/constants'
import { selectECommerce } from 'features/eCommerceSlice'
import { useECommerce } from 'ECommerce/hooks/useECommerce'
import { siteModule } from 'config'
import { useBusiness } from 'backend/useBusiness'
import Checkout from 'ECommerce/PaymentPages/Checkout'
import { isEmpty } from 'utils/helpers'

// http://localhost:3000/payment/addendum/cm0p3tr3q0001zidh9pti31es
const PaymentOptionAddendum = () => {
  const navigate = useNavigate()
  const { paymentMethod, products, product, checkout, coupon } =
    useSelector(selectECommerce)
  const { id } = useParams()
  const { selectProduct } = useECommerce()
  const { price } = usePrice()
  const [currentBusiness, setCurrentBusiness] = useState({})
  const [addendumDetails, setAddendumDetails] = useState({})
  const [addendumName, setAddendumName] = useState('')
  const { makeOrder, coinbaseCreateCharge, stripeCreateCheckoutSession } =
    useOrder()
  const { getAddendumDetails } = useBusiness()

  useEffect(() => {
    if (id) {
      if (isEmpty(addendumDetails)) {
        getAddendumDetails(id).then((item) => {
          setCurrentBusiness(item.business)
          delete item.business
          setAddendumDetails(item)
          setAddendumName(item.oldFullName + ' ⟶ ' + item.newFullName)
        })
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id])

  useEffect(() => {
    if (currentBusiness?.id) {
      if (products?.items.length > 0) {
        selectProduct(`${siteModule}-name-addendum`)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [products, currentBusiness])

  const initiateOrder = async () => {
    await getAddendumDetails(id)

    makeOrder({
      title: product.name + ' - ' + addendumName,
      items: [
        {
          refId: id,
          amount: product.payableAmount,
          name: addendumName,
          title: product.name,
          description: product.description,
          quantity: 1,
        },
      ],
      type: orderTypes.NameAddendum,
      gateway: paymentMethod,
      amount: checkout.total,
      currency: 'USD',
      coupon: coupon,
    }).then((order) => {
      if (paymentMethod === 'AdminPayment') {
        console.log(order)
        navigate(`/payment/admin-payment/${order.id}`)
        return
      } else if (paymentMethod === 'CoinBase') {
        coinbaseCreateCharge({
          name: addendumName,
          description: product.description,
          items: [
            {
              amount: checkout.total,
              name: product.name,
              description: product.description,
              quantity: 1,
            },
          ],
          orderId: order.id,
          currency: 'USD',
        }).then((charge) => {
          window.location.href = charge.url
        })
        return
      } else if (paymentMethod === 'Stripe') {
        stripeCreateCheckoutSession({
          title: product.name,
          items: [
            {
              amount: checkout.total,
              name: product.name,
              description: product.description,
              quantity: 1,
            },
          ],
          orderId: order.id,
          currency: 'USD',
        }).then((session) => {
          window.location.href = session.url
        })
        return
        // } else if (paymentMethod === 'Square') {
        //   console.log(order)
        //   navigate(`/payment/square/${order.id}`)
        //   return
        // } else if (paymentMethod === 'Paypal') {
        //   console.log(order)
        //   navigate(`/payment/paypal/${order.id}`)
        //   return
        // } else if (paymentMethod === 'OnRamper') {
        //   console.log(order)
        //   navigate(`/payment/onramper/${order.id}`)
        //   return
        // } else if (paymentMethod === 'ForumPay') {
        //   navigate('/payment/forumpay/' + order.id)
      } else {
        navigate('/payment/done/' + order.id)
      }
    })
  }

  if (!product?.id) {
    return (
      <div className="h-96 flex items-center justify-center">
        <h2 className="text-2xl">Product details loading...</h2>
      </div>
    )
  }

  const cancelAddendum = () => {}

  return (
    <div>
      {/* <pre>{JSON.stringify(coupon, null, 2)}</pre> */}
      <div className="text-xs">
        <span className="text-muted">Make Payment For</span>
        <span className="dot">
          {product.name} - {currentBusiness?.businessFullName}
        </span>
      </div>
      <div
        className="my-5"
        dangerouslySetInnerHTML={{
          __html: String(product.paymentDescription).replace(
            '{price}',
            price(product.payableAmount)
          ),
        }}
      />
      {/* <div className="text-center mt-5">
        <h2 class="text-xl mb-3">Payment</h2>
        <div className="text-muted mb-3 pb-4">
          After successful payment you can download immediately all registration
          certificates of your new {entityName}. You can pay with Cryptocurrency
          or with your credit card. The total amount is only{' '}
          <b>{price(product.payableAmount)}</b> for your {entityName}{' '}
          registration.
          <p>
            Any questions, feel free to send us an email:{' '}
            <a href="mailto:register@blockchaintrust.pro">
              register@blockchaintrust.pro
            </a>
          </p>
        </div>
      </div> */}
      {/* '&#8680; &#8674;  &#8658; &rarr; */}
      <div>
        <Checkout
          initiateOrder={initiateOrder}
          items={[
            {
              name: addendumName,
            },
          ]}
        />
      </div>
      <div className="flex justify-between mt-10">
        <button
          type="button"
          className="btn-back"
          tabIndex="-1"
          onClick={cancelAddendum}
        >
          Cancel
        </button>
        {/* {paymentMethod ? (
          <button className="btn-primary" onClick={initiateOrder}>
            Proceed with {paymentMethod}
          </button>
        ) : (
          <button className="btn-primary" disabled>
            Select Payment Method
          </button>
        )} */}
      </div>
    </div>
  )
}

export default PaymentOptionAddendum

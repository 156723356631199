import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useAuth } from 'backend/useAuth'
import { LoaderSuspense } from 'utils/loader'
import { useDispatch } from 'react-redux'
import { businessActions } from 'features/businessSlice'
import { orderActions } from 'features/ordersSlice'
import { shareholderActions } from 'features/shareholderSlice'
import { eCommerceActions } from 'features/eCommerceSlice'

const Logout = () => {
  const { logout } = useAuth()
  const dispatch = useDispatch()
  const navigate = useNavigate()

  useEffect(() => {
    logout().then(() => {
      dispatch(businessActions.clear())
      dispatch(orderActions.clear())
      dispatch(shareholderActions.clear())
      dispatch(eCommerceActions.clear())
      navigate('/auth/login')
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div className="h-screen">
      <LoaderSuspense />
    </div>
  )
}

export default Logout

export const getLinkByBusinessType = (business, endpoint) => {
  let link = ''
  switch (business.businessType) {
    case 'TRUST':
      link = '/register/trust'
      break
    case 'FAMILY':
      link = '/register/family'
      break
    case 'CORP':
      link = '/register/corporate'
      break
    case 'BANK':
      link = '/register/bank'
      break
    case 'INSURANCE':
      link = '/register/insurance'
      break
    default:
      return '/404'
  }
  if (endpoint === 'payment') {
    return '/payment/register/' + business.id
  }
  if (endpoint === 'edit') {
    return link + '/' + business.id + '/business'
  }
}

import React from 'react'
import { Link } from 'react-router-dom'
import { useAuth } from 'backend/useAuth'
import { Form, Input, Submit } from 'tui'

const Forgot = () => {
  const { forgotPassword } = useAuth()
  const onSubmit = (data) => {
    forgotPassword(data.email)
  }

  return (
    <div className="md:w-1/3 bg-dark-900 mx-auto p-8 flex flex-col justify-center">
      <div className="text-center">
        <h2 className="text-4xl mb-3">Forgot Password</h2>
        <p className="text-muted text-sm">
          You will receive email with password reset link.
        </p>
      </div>
      <Form onFinish={onSubmit}>
        <div>
          <Input.Email
            name="email"
            label="Email"
            placeholder="Email"
            rules={{ required: 'Email is required' }}
          />
          <div className="flex justify-between items-center mt-5">
            <Submit className="btn-primary py-2">Reset</Submit>
            <span>
              Back to <Link to="/auth/login">Login</Link>
            </span>
            {/* <Link to="/">Back to Login</Link> */}
          </div>
        </div>
      </Form>
    </div>
  )
}

export default Forgot

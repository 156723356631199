// import { lazy } from 'react';

// project imports
import AuthLayout from 'Layouts/AuthLayout'

import AuthGuard from './AuthGuard'
import UpdatePass from 'Pages/Auth/UpdatePass'
// import PaymentOptionAgent from "ECommerce/PaymentPages/PaymentOptionAgent";
import SingleLayout from 'Layouts/SingleLayout'
import AccountSettings from 'Pages/Reseller/AccountSettings'
import AccountAddress from 'Pages/Reseller/AccountSettings/AccountAddress'
import BillingPreference from 'Pages/Reseller/AccountSettings/BillingPreference'
// import PaymentLayout from "Layouts/PaymentLayout";

// const Page404 = lazy(() => import('Pages/404'));

// ==============================|| AUTH ROUTING ||============================== //

const AccountRoutes = {
  path: '/account',
  children: [
    {
      path: '',
      element: (
        <AuthGuard>
          <SingleLayout />
        </AuthGuard>
      ),
      children: [
        {
          index: true,
          element: <AccountSettings />,
        },
        {
          path: 'address',
          element: <AccountAddress />,
        },
        {
          path: 'bill-preference',
          element: <BillingPreference />,
        },
      ],
    },
    // {
    //   path: "payment",
    //   element: (
    //     <AuthGuard>
    //       <PaymentLayout />
    //     </AuthGuard>
    //   ),
    //   children: [
    //     {
    //       path: ":sub",
    //       element: <PaymentOptionAgent />,
    //     },
    //   ],
    // },
    {
      path: 'password',
      element: (
        <AuthGuard>
          <AuthLayout />
        </AuthGuard>
      ),
      children: [
        {
          index: true,
          element: <UpdatePass />,
        },
      ],
    },
  ],
}

export default AccountRoutes

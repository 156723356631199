import React, { Fragment, useRef } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { FaTimes } from 'react-icons/fa'
import { useDispatch, useSelector } from 'react-redux'
import { selectUi, uiActions } from 'features/uiSlice'

const ReusableModal = ({ title, children }) => {
  const dispatch = useDispatch()
  const { reUsableModal } = useSelector(selectUi)

  const handleClose = () => {
    dispatch(uiActions.closeReUsableModal())
  }

  const cancelButtonRef = useRef(null)

  return (
    <Transition.Root show={reUsableModal?.open} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-10"
        initialFocus={cancelButtonRef}
        onClose={handleClose}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed z-10 inset-0 overflow-y-auto">
          <div className="flex items-end sm:items-center justify-center min-h-full px-6 pt-0 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative bg-black rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-4 sm:max-w-2xl sm:w-full p-6">
                <div className="flex justify-between mb-6">
                  <Dialog.Title
                    as="h3"
                    className="text-lg leading-6 font-medium"
                  >
                    {title}
                  </Dialog.Title>
                  <FaTimes
                    className="text-muted text-lg cursor-pointer hover:text-red-400 duration-200"
                    onClick={handleClose}
                  />
                </div>
                {children}
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}

export default ReusableModal

import { lazy } from 'react'

// project imports
import AuthGuard from './AuthGuard'
import PageLayout from 'Layouts/PageLayout'
import SiteModuleGuard from './SiteModuleGuard'

const AddendumBusiness = lazy(() => import('Pages/Reseller/Addendum'))

// ==============================|| PAGES ROUTING ||============================== //

const AddendumRoutes = {
  path: '/addendum',
  children: [
    {
      path: '*',
      element: (
        <AuthGuard>
          <SiteModuleGuard>
            <PageLayout />
          </SiteModuleGuard>
        </AuthGuard>
      ),
      children: [
        {
          index: true,
          element: <AddendumBusiness />,
        },
      ],
    },
  ],
}

export default AddendumRoutes

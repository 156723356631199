import cx from 'classnames'
import { useFormContext, Controller } from 'react-hook-form'
import ReactDatePicker from 'react-datepicker'

const DatePicker = ({
  name = '',
  label = '',
  required = false,
  placeholder = '',
  defaultValue = '',
  className = '',
  rules,
  pickerOptions,
}) => {
  const {
    formState: { errors },
    control,
  } = useFormContext() // retrieve all hook methods

  return (
    <div className="mb-1">
      {label && <label className={cx({ required })}>{label}</label>}
      <Controller
        name={name}
        control={control}
        rules={rules}
        defaultValue={defaultValue}
        render={({
          field: { onChange, onBlur, value, name, ref },
          // fieldState: { invalid, isTouched, isDirty, error },
          // formState,
        }) => (
          <>
            <ReactDatePicker
              {...pickerOptions}
              selected={(value && new Date(value)) || ''}
              className={cx(className, 'tw-control', {
                invalid: errors[name]?.message,
              })}
              placeholderText={placeholder}
              onBlur={onBlur} // notify when input is touched
              onChange={onChange} // send value to hook form
              // onChange={(date, e) => onChange(date.toDateString())} // send value to hook form
              inputRef={ref}
            />
          </>
        )}
      ></Controller>
      {errors[name]?.message && (
        <div className="invalid-msg">{errors[name]?.message}</div>
      )}
    </div>
  )
}

export default DatePicker

import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import {
  selectBeneficiary,
  beneficiaryActions,
} from 'features/beneficiarySlice'
import { useBackend } from './useBackend'
import { status } from 'features/status'
import { paginationAction } from 'features/pagination/paginationSlice'

export function useBeneficiary() {
  const { beneficiary, beneficiaryOptions } = useSelector(selectBeneficiary)
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { backend } = useBackend()

  const createBeneficiary = (payload, config) =>
    new Promise((resolve) => {
      backend()
        .post('/beneficiary-business', payload, config)
        .then(({ item }) => {
          dispatch(
            beneficiaryActions.add({
              item: item,
              status: status.idle,
            })
          )
          return resolve(item.id)
        })
    })

  const updateBeneficiary = (
    payload,
    msg = { loading: 'Updating...', success: 'Updated.' },
    loader = true
  ) =>
    new Promise((resolve) => {
      let config = { loader, msg }
      const { id, ...body } = payload
      backend()
        .put(`/beneficiary-business/${id}`, body, config)
        .then(({ item }) => {
          dispatch(
            beneficiaryActions.update({
              item: item,
              status: status.idle,
            })
          )
          return resolve(item.id)
        })
    })

  const searchBeneficiary = (payload) =>
    new Promise((resolve) => {
      let config = {
        loader: true,
        msg: false,
      }
      const query = new URLSearchParams(payload).toString()
      backend()
        .get(`/beneficiary-business/search?${query}`, config)
        .then((data) => {
          return resolve(data)
        })
        .catch((err) => {
          console.log(err)
        })
    })

  const myBeneficiary = (payload) =>
    new Promise((resolve) => {
      let config = {
        loader: false,
        msg: false,
      }
      // const query = new URLSearchParams(payload).toString()
      const query = new URLSearchParams({ ...payload }).toString()
      dispatch(beneficiaryActions.loading())
      backend()
        .get(`/beneficiary-business/my-beneficiaries?${query}`, config)
        .then((data) => {
          dispatch(
            beneficiaryActions.loaded({
              items: data.items,
              status: status.idle,
            })
          )
          return resolve(data)
        })
        .catch((err) => {
          console.log(err)
          dispatch(beneficiaryActions.error())
        })
    })

  const getBeneficiary = (id, redirect = true) =>
    new Promise((resolve) => {
      let config = { loader: true, msg: null }
      backend()
        .get(`/beneficiary-business/${id}`, config)
        .then(({ item }) => {
          // dispatch(beneficiaryActions.current({ item: item }))
          dispatch(beneficiaryActions.append({ item: item }))
          return resolve(item)
        })
        .catch((error) => {
          if (redirect) {
            navigate(`/`)
          }
        })
    })

  const getBeneficiaryOptionsByType = (type) => {
    return beneficiary.items
      .filter((item) => item.type === type)
      .map((item) => {
        return {
          label: `${item.firstName} ${item.lastName} ${item.businessName} - ${item.email}`,
          value: item.id,
        }
      })
  }

  const onSelectBeneficiary = (id) => {
    return beneficiary.items.filter((item) => item.id === id)
  }

  const deleteBeneficiary = (id) =>
    new Promise((resolve) => {
      let config = {
        loader: true,
        msg: { loading: 'Processing...', success: 'Beneficiary deleted' },
      }
      backend()
        .delete(`/beneficiary-business/${id}`, config)
        .then(({ item }) => {
          dispatch(paginationAction.deleteById({ id }))
          dispatch(beneficiaryActions.deleteById({ id }))
          return resolve(item)
        })
        .catch((error) => {})
    })

  return {
    beneficiary,
    beneficiaryOptions,
    createBeneficiary,
    updateBeneficiary,
    searchBeneficiary,
    getBeneficiaryOptionsByType,
    onSelectBeneficiary,
    myBeneficiary,
    getBeneficiary,
    deleteBeneficiary,
  }
}

import { useEffect } from 'react'
// import PropTypes from "prop-types";
import { useDispatch, useSelector } from 'react-redux'
import {
  paginationAction,
  selectPagination,
} from 'features/pagination/paginationSlice'
import { showLoader } from 'utils/loader'
import { useBackend } from './useBackend'
import { useLocation } from 'react-router-dom'

/**
 *
 * @param {*} endpoint
 * @param {*} query
 * @returns
 */
const usePaginate = (endpoint, query = {}) => {
  const { backend } = useBackend()
  const { pathname } = useLocation()
  const {
    prevPath,
    endpoint: endpointUrl,
    loadingMore,
    loadingError,
    loadingMoreError,
    loading,
    hasMore,
    items,
    total,
    needRefresh,
    skip,
    filterBy,
  } = useSelector(selectPagination)
  const dispatch = useDispatch()

  useEffect(() => {
    if (prevPath !== pathname) {
      clear()
    }
    return () => {
      clear()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname])

  // console.log(endpointUrl, endpoint);

  useEffect(() => {
    if (prevPath === pathname) {
      return
    }
    if (loading) {
      return
    }
    const { limit = 50, orderBy = 'createdAt', order = 'desc' } = query
    let config = {}
    const params = new URLSearchParams({
      ...query,
      limit,
      orderBy,
      order,
      skip: 0,
    }).toString()
    dispatch(
      paginationAction.pagination({
        limit,
        endpoint,
        prevPath: pathname,
        skip,
      })
    )

    backend()
      .get(`/${endpoint}?${params}`, config)
      .then(({ items, total }) => {
        dispatch(paginationAction.loaded({ items, total }))
      })
      .catch((e) => {
        dispatch(paginationAction.failed())
      })
    // return () => paginationAction.clearAction();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [endpoint, endpointUrl, needRefresh])

  useEffect(() => {
    showLoader(loading)
  }, [loading])

  useEffect(() => {
    if (loadingError) {
      showLoader(false)
    }
  }, [loadingError])

  const filter = async (filterBy) => {
    if (loading) {
      return
    }
    const { limit = 100, orderBy = 'createdAt', order = 'desc' } = query
    let config = {
      loader: true,
      msg: {
        loading: 'Loading...',
        success: 'Loaded successfully.',
      },
    }
    const params = new URLSearchParams({
      ...query,
      ...filterBy,
      limit,
      orderBy,
      order,
      skip: 0,
    }).toString()
    dispatch(paginationAction.clear())
    dispatch(paginationAction.loading())
    backend()
      .get(`/${endpoint}?${params}`, config)
      .then(({ items, total }) => {
        dispatch(paginationAction.loaded({ items, total, filterBy }))
        console.log('filter loaded ✔️')
      })
      .catch((e) => {
        dispatch(paginationAction.failed())
      })
  }

  const loadMore = async () => {
    if (loading) {
      return
    }
    if (hasMore === false) {
      return
    }
    const { limit = 100, orderBy = 'createdAt', order = 'desc' } = query
    // when "skip" changes, we update our query
    // let config = {};
    let config = {
      loader: true,
      msg: {
        loading: 'Loading more...',
        success: 'Loaded successfully.',
      },
    }
    // console.log('useNestPaginate', state)
    const params = new URLSearchParams({
      ...query,
      ...filterBy,
      limit,
      orderBy,
      order,
      skip: skip || 0,
    }).toString()
    dispatch(paginationAction.loading())
    try {
      const { items } = await backend().get(`/${endpoint}?${params}`, config)
      dispatch(paginationAction.moreLoaded({ items }))
      console.log('loaded ✔️')
    } catch (e) {
      dispatch(paginationAction.failed())
    }
  }

  const refresh = () => {
    dispatch(paginationAction.refresh())
  }

  const deleteById = (id) => {
    dispatch(paginationAction.deleteById({ id }))
  }

  const updateById = (id, item) => {
    dispatch(paginationAction.updateById({ id, item }))
  }

  const clear = () => {
    dispatch(paginationAction.clear())
    dispatch(paginationAction.clearFilter())
  }

  return {
    loadingMore,
    loadingError,
    loadingMoreError,
    loading,
    hasMore,
    items,
    total,
    loadMore,
    refresh,
    deleteById,
    updateById,
    clear,
    filter,
  }
}

// usePaginate.prototype = {
//   endpoint: PropTypes.string.isRequired,
//   query: PropTypes.object.isRequired,
// };

// // Set default props
// usePaginate.defaultProps = {
//   query: { limit: 20, orderBy: "createdAt", order: -1 },
// };

export { usePaginate }

import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { countryNameByIso2 } from 'dataSource/countries'
import { useBusiness } from 'backend/useBusiness'
import { config } from 'config'

const contractExplorerUrl = config.contractExplorerUrl
const businessContractAddress = config.businessContractAddress
const openSeaUrl = config.openSeaUrl

const CompanyDetails = () => {
  const { id } = useParams()
  const [formData, setFormData] = useState({})

  const { getBusiness } = useBusiness()

  useEffect(() => {
    getBusiness(id).then((item) => setFormData(item))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id])

  return (
    <div>
      <div>
        <h2 className="text-2xl mb-5">Company Details</h2>
      </div>
      <div>
        {/* <pre>{JSON.stringify(formData, null, 2)}</pre> */}
        <div className="mb-4 rounded-md w-full">
          <div className="font-semibold">
            <div>
              <div className="bg-black text-sm text-muted p-4 rounded-lg">
                <div className="flex justify-between mb-4">
                  <div className="text-lg">{formData?.businessFullName}</div>
                </div>
                <div className="grid md:grid-cols-2 gap-4">
                  <div>
                    <div className="mb-2">
                      <span className="text-muted">BICRA: </span>
                      <b>{formData?.bicraFormatted}</b>
                    </div>
                    <div className="mb-2">
                      <span className="text-muted">Filing Number: </span>
                      <b>{formData?.filingNumberFormatted}</b>
                    </div>
                    <div className="mb-2">
                      <span className="text-muted">Phone Number: </span>
                      <b>{formData?.landLine}</b>
                    </div>
                    <div className="mb-2">
                      <span className="text-muted">Email: </span>
                      <b>{formData?.email}</b>
                    </div>
                    <div className="mb-2">
                      <span className="text-muted">Business Residency: </span>
                      <b>{countryNameByIso2(formData?.country)}</b>
                    </div>
                  </div>
                  <div>
                    <p className="mb-2">
                      <b className="text-muted">ADDRESS: </b>
                    </p>
                    <span className="mr-1">{formData?.street}</span>
                    <div className="mr-1">{formData?.city}</div>
                    <span className="mr-1">{formData?.state}</span>
                    <span className="mr-1">{formData?.zipCode}</span>
                    <div className="mr-1">
                      {countryNameByIso2(formData?.country)}
                    </div>
                  </div>
                </div>
                {formData.transactionHash && (
                  <div>
                    <a
                      href={`${contractExplorerUrl}/tx/${formData.transactionHash}`}
                      target="_blank"
                      rel="noreferrer"
                      className="link block my-2"
                    >
                      View it on Polygon Explorer
                    </a>
                    <a
                      href={`${openSeaUrl}/${businessContractAddress}/${Number(
                        formData?.filingNumberFormatted
                      )}`}
                      target="_blank"
                      rel="noreferrer"
                      className="link block"
                    >
                      View it on OpenSea
                    </a>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default CompanyDetails

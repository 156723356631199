import React from 'react'
import { useNavigate } from 'react-router-dom'
import { FaPercent } from 'react-icons/fa'
import { DatePicker, Form, Input, Select, Submit } from 'tui'
import { countriesOptions } from 'dataSource/countries'
import { maritalStatus } from 'dataSource/options'

const EntityFormIndividual = ({
  data,
  onSubmit,
  action,
  title = 'Individual Details',
}) => {
  const navigate = useNavigate()

  return (
    <div>
      <div className="text-center mt-5">
        <h2 className="text-xl mb-3">{title}</h2>
      </div>
      {/* <pre>{JSON.stringify(formData, null, 2)}</pre> */}
      <Form onFinish={onSubmit} initialValues={data}>
        <div className="grid grid-cols-2 gap-5 mt-5">
          <div className="w-full">
            <Input.Text
              name="firstName"
              required
              label="First Name"
              placeholder="First Name"
              rules={{ required: 'First Name is required' }}
            />
          </div>
          <div className="w-full">
            <Input.Text
              name="lastName"
              required
              label="Last Name"
              placeholder="Last Name"
              rules={{ required: 'Last Name is required' }}
            />
          </div>
          <div className="w-full">
            <Input.Email
              name="email"
              required
              label="Email"
              placeholder="Email"
              rules={{ required: 'Email is required' }}
            />
          </div>
          <div className="w-full">
            <Input.Text
              name="mobile"
              required
              label="Mobile Number"
              placeholder="Mobile Number"
              rules={{ required: 'Mobile Number is required' }}
            />
          </div>
          <div className="w-full">
            <Input.Text
              name="passportNumber"
              required
              label="Passport/ID Number"
              placeholder="Passport/ID Number"
              rules={{ required: 'Passport/ID Number is required' }}
            />
          </div>
          <div className="w-full">
            <DatePicker
              name="dob"
              required
              label="Birth Date"
              placeholder="Birth Date"
              rules={{ required: 'Birth Date is required' }}
              pickerOptions={{
                // minDate: subYears(new Date(), 60),
                // maxDate: subYears(new Date(), 18),
                dateFormat: 'dd/MM/yyyy',
                closeOnScroll: true,
                // showMonthYearPicker: true,
                showMonthDropdown: true,
                // useShortMonthInDropdown: true,
                showYearDropdown: true,
                // showMonthDropdown
                // showYearDropdown
                dropdownMode: 'select',
                // withPortal: true
              }}
            />
          </div>
          <div className="w-full">
            <Select
              required
              name="maritalStatus"
              label="Marital Status"
              placeholder="Select Marital Status"
              options={maritalStatus}
              rules={{ required: 'Marital Status is required' }}
            />
          </div>
          <div className="w-full">
            <Input.Number
              name="share"
              required
              label="Share"
              placeholder="Share in %"
              min="0"
              max="100"
              icon={<FaPercent />}
              rules={{ required: 'Share is required' }}
            />
          </div>
          <div className="w-full col-span-2">
            <div className="text-lg text-muted">
              Place of Birth / Country of Citizenship
            </div>
          </div>
          {/* <div className="w-full">
            <Input.Text
              name="street"
              required
              label="Street"
              placeholder="Street"
              rules={{ required: "Street is required" }}
            />
          </div> */}
          <div className="w-full">
            <Input.Text
              name="city"
              required
              label="City"
              placeholder="City"
              rules={{ required: 'City is required' }}
            />
          </div>
          <div className="w-full">
            <Input.Text
              name="zipCode"
              required
              label="Zip Code"
              placeholder="Zip Code"
              rules={{ required: 'Zip Code is required' }}
            />
          </div>
          <div className="w-full">
            <Input.Text
              name="state"
              required
              label="State"
              placeholder="State"
              rules={{ required: 'State is required' }}
            />
          </div>
          <div className="w-full">
            <Select
              required
              name="country"
              label="Country of Citizenship"
              placeholder="Select Country"
              options={countriesOptions}
              rules={{ required: 'Country is required' }}
            />
          </div>
        </div>
        <div className="flex justify-between mt-5">
          <button
            type="button"
            className="btn-back"
            tabIndex="-1"
            onClick={() => navigate(-1)}
          >
            Back
          </button>
          <Submit className="btn-primary">
            {action === 'add' ? 'Add' : 'Update'}
          </Submit>
        </div>
      </Form>
    </div>
  )
}

export default EntityFormIndividual

import React, { useState } from 'react'
import dayjs from 'dayjs'
import { FaSearch } from 'react-icons/fa'

import Placeholder from 'Components/Placeholder'
import { Form, Input, Select, Submit } from 'tui'
import { LoaderTextBlock } from 'utils/loader'
import { useBusiness } from 'backend/useBusiness'
import {
  btEntityEndings,
  corpEntityEndings,
  familyEntityEndings,
} from 'dataSource/options'

const defaultData = {
  items: [],
  status: 'idle',
}
const CompanySearch = () => {
  const [filtered, setFiltered] = useState(false)
  const [business, setBusiness] = useState(defaultData)
  const { searchBusiness } = useBusiness()

  const onSubmit = (data) => {
    searchBusiness({ ...data }).then((result) => {
      setBusiness({
        items: result.items,
        status: 'idle',
      })

      setFiltered(true)
    })

    console.log('data', data)
  }

  const clearFilter = () => {
    setBusiness(defaultData)
    setFiltered(false)
  }

  return (
    <div>
      <h1 className="text-3xl mb-4">Company Name Search</h1>
      <div className="mb-5">
        <div className="text-muted">
          Are you ready to create your Blockchain Trust? Start here by entering
          your desired company name. We'll check your submission against the
          Blockchain Trust name database.
        </div>
        <div className="text-muted">
          This database determines if a business name is available for use in
          forming a new Blockchain Trust company.
        </div>
        <div className="text-muted">
          Our online technology allows us to quickly and seamlessly determine
          the availability of a name for use in forming a new Blockchain Trust
          entity.
        </div>
      </div>
      <Form onFinish={onSubmit}>
        <div className="md:flex justify-between items-start">
          <div className="flex space-x-4">
            <div>
              <Input.Text
                name="businessName"
                placeholder="Search by Company Name"
              />
            </div>
            <div>
              <Select
                name="entityEnding"
                // label="Entity Ending"
                placeholder="Select Entity Ending"
                options={[].concat(
                  btEntityEndings,
                  corpEntityEndings,
                  familyEntityEndings
                )}
              />
            </div>
          </div>
          <div className="flex items-center">
            <Submit className="bg-primary flex items-center p-2 px-4 mr-2">
              <FaSearch className="mr-2" />
              <span>Search</span>
            </Submit>
            {filtered && (
              <button
                type="reset"
                className="flex items-center bg-input p-2 px-4"
                onClick={clearFilter}
              >
                Clear Search
              </button>
            )}
          </div>
        </div>
      </Form>
      <div className="mt-2">
        <div>
          {business.status === 'loading' ? (
            <LoaderTextBlock />
          ) : business?.items?.length === 0 ? (
            <Placeholder
              icon={FaSearch}
              text={
                filtered
                  ? 'No result found'
                  : 'Type company name and click on search to get result.'
              }
            />
          ) : (
            business?.items?.map((item) => (
              <div className="bg-black my-4 rounded-md p-2">
                <p className="mt-2 mx-2">
                  <p className="text-gold-400">{item.businessFullName}</p>
                  {/* <span className="pl-2 text-muted text-xs">{item.status}</span> */}
                </p>
                <p className="font-semibold mx-2 ">
                  <div className="flex justify-between space-x-4 items-center">
                    <div className="w-2/4">
                      <p className="text-muted">
                        BT formed on{' '}
                        {dayjs(item.createdAt).format('MMM DD, YYYY')}
                      </p>
                      <p className="mt-2 text-xs text-gold-400">
                        {item.bicraFormatted}
                      </p>
                    </div>
                    {/* <p className="text-muted hidden md:block">Registered as {item.registeredAs}</p> */}
                    {/* <p className="rounded-md mb-2">
                        <Badge status={item.status}>{item.dueDate}</Badge>
                      </p> */}
                  </div>
                </p>
              </div>
            ))
          )}
        </div>
      </div>
    </div>
  )
}

export default CompanySearch

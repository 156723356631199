import React from 'react'
import { Link, Outlet } from 'react-router-dom'

import logo from '../assets/logo-banner.jpg'

const AuthLayout = () => {
  return (
    <div className="flex flex-col justify-center min-h-[100vh]">
      <div>
        <div className="w-36 h-24 mx-auto mb-8">
          <Link to="/">
            <img src={logo} className="inline-block" alt="" />
          </Link>
        </div>
        <Outlet />
      </div>
    </div>
  )
}

export default AuthLayout

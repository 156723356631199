import { useState } from 'react'
import cx from 'classnames'
import { useFormContext } from 'react-hook-form'

// eslint-disable-next-line
let rEmail = /^((([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+(\.([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+)*)|((\x22)((((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(([\x01-\x08\x0b\x0c\x0e-\x1f\x7f]|\x21|[\x23-\x5b]|[\x5d-\x7e]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(\\([\x01-\x09\x0b\x0c\x0d-\x7f]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))))*(((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(\x22)))@((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))$/i

const Text = ({
  name = '',
  label = '',
  required = false,
  placeholder = '',
  defaultValue = '',
  className = '',
  rules,
  icon,
  ...restProps
}) => {
  const {
    register,
    formState: { errors },
  } = useFormContext() // retrieve all hook methods

  return (
    <div className="mb-1">
      <div className="relative">
        {label && <label className={cx({ required })}>{label}</label>}
        <input
          name={name}
          className={cx(className, 'tw-control', {
            invalid: errors[name]?.message,
          })}
          placeholder={placeholder}
          defaultValue={defaultValue}
          {...register(name, { ...rules })}
          {...restProps}
        />
        {icon && (
          <span className="absolute right-0 bottom-0 cursor-pointer border-2 border-transparent rounded-lg rounded-tl-none rounded-bl-none text-center overflow-hidden py-3 px-4 bg-primary">
            {icon}
          </span>
        )}
      </div>
      {errors[name]?.message && (
        <div className="invalid-msg">{errors[name]?.message}</div>
      )}
    </div>
  )
}

const Hidden = ({ name = '', defaultValue = '', ...restProps }) => {
  const { register } = useFormContext() // retrieve all hook methods

  return (
    <input
      name={name}
      type="hidden"
      defaultValue={defaultValue}
      {...register(name)}
      {...restProps}
    />
  )
}

const Number = ({
  name = '',
  label = '',
  min = 0,
  max = '',
  required = false,
  placeholder = '',
  defaultValue = '',
  className = '',
  rules,
  icon,
  ...restProps
}) => {
  const {
    register,
    formState: { errors },
  } = useFormContext() // retrieve all hook methods

  return (
    <div className="mb-1">
      <div className="relative">
        {label && <label className={cx({ required })}>{label}</label>}
        <input
          name={name}
          className={cx(className, 'tw-control', {
            invalid: errors[name]?.message,
          })}
          placeholder={placeholder}
          defaultValue={defaultValue}
          min={min}
          max={max}
          type="number"
          {...register(name, { ...rules })}
          {...restProps}
        />
        {icon && (
          <span className="absolute right-0 bottom-0 cursor-pointer border-2 border-transparent rounded-lg rounded-tl-none rounded-bl-none text-center overflow-hidden py-3 px-4 bg-primary">
            {icon}
          </span>
        )}
      </div>
      {errors[name]?.message && (
        <div className="invalid-msg">{errors[name]?.message}</div>
      )}
    </div>
  )
}

const Email = ({
  name = '',
  label = '',
  required = false,
  placeholder = '',
  defaultValue = '',
  className = '',
  rules,
  ...restProps
}) => {
  const {
    register,
    formState: { errors },
  } = useFormContext() // retrieve all hook methods

  return (
    <div className="mb-1">
      {label && <label className={cx({ required })}>{label}</label>}
      <input
        name={name}
        className={cx(className, 'tw-control', {
          invalid: errors[name]?.message,
        })}
        placeholder={placeholder}
        defaultValue={defaultValue}
        {...register(name, {
          ...rules,
          pattern: {
            value: rEmail,
            message: 'Email is not valid',
          },
        })}
        {...restProps}
      />
      {errors[name]?.message && (
        <div className="invalid-msg">{errors[name]?.message}</div>
      )}
    </div>
  )
}

const Password = ({
  name = '',
  label = '',
  required = false,
  placeholder = '',
  defaultValue = '',
  className = '',
  rules = {},
  ...restProps
}) => {
  const {
    register,
    formState: { errors },
  } = useFormContext() // retrieve all hook methods
  const [type, setType] = useState('password')

  const handleShowPassword = () => {
    let newType = type === 'password' ? 'text' : 'password'
    setType(newType)
  }

  const { compareWith, ...options } = rules
  // rules.onChange((e) => {console.log('inner', e.target.value)})

  return (
    <div className="mb-1">
      <div className="relative">
        {label && <label className={cx({ required })}>{label}</label>}
        <input
          name={name}
          className={cx(className, 'tw-control', {
            invalid: errors[name]?.message,
          })}
          placeholder={placeholder}
          defaultValue={defaultValue}
          {...register(name, options)}
          type={type}
          {...restProps}
        />
        <button
          className="absolute right-0 bottom-0 cursor-pointer border-2 border-transparent rounded-lg rounded-tl-none rounded-bl-none text-center overflow-hidden py-3 px-4 primary"
          onClick={handleShowPassword}
          type="button"
          tabIndex={-1}
        >
          {type === 'password' ? (
            <svg
              stroke="currentColor"
              fill="currentColor"
              strokeWidth="0"
              viewBox="0 0 576 512"
              height="1em"
              width="1em"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M572.52 241.4C518.29 135.59 410.93 64 288 64S57.68 135.64 3.48 241.41a32.35 32.35 0 0 0 0 29.19C57.71 376.41 165.07 448 288 448s230.32-71.64 284.52-177.41a32.35 32.35 0 0 0 0-29.19zM288 400a144 144 0 1 1 144-144 143.93 143.93 0 0 1-144 144zm0-240a95.31 95.31 0 0 0-25.31 3.79 47.85 47.85 0 0 1-66.9 66.9A95.78 95.78 0 1 0 288 160z"></path>
            </svg>
          ) : (
            <svg
              stroke="currentColor"
              fill="currentColor"
              strokeWidth="0"
              viewBox="0 0 640 512"
              height="1em"
              width="1em"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M320 400c-75.85 0-137.25-58.71-142.9-133.11L72.2 185.82c-13.79 17.3-26.48 35.59-36.72 55.59a32.35 32.35 0 0 0 0 29.19C89.71 376.41 197.07 448 320 448c26.91 0 52.87-4 77.89-10.46L346 397.39a144.13 144.13 0 0 1-26 2.61zm313.82 58.1l-110.55-85.44a331.25 331.25 0 0 0 81.25-102.07 32.35 32.35 0 0 0 0-29.19C550.29 135.59 442.93 64 320 64a308.15 308.15 0 0 0-147.32 37.7L45.46 3.37A16 16 0 0 0 23 6.18L3.37 31.45A16 16 0 0 0 6.18 53.9l588.36 454.73a16 16 0 0 0 22.46-2.81l19.64-25.27a16 16 0 0 0-2.82-22.45zm-183.72-142l-39.3-30.38A94.75 94.75 0 0 0 416 256a94.76 94.76 0 0 0-121.31-92.21A47.65 47.65 0 0 1 304 192a46.64 46.64 0 0 1-1.54 10l-73.61-56.89A142.31 142.31 0 0 1 320 112a143.92 143.92 0 0 1 144 144c0 21.63-5.29 41.79-13.9 60.11z"></path>
            </svg>
          )}
        </button>
      </div>
      {errors[name]?.message && (
        <div className="invalid-msg">{errors[name]?.message}</div>
      )}
    </div>
  )
}

const TextArea = ({
  name = '',
  label = '',
  required = false,
  placeholder = '',
  defaultValue = '',
  className = '',
  rules,
  ...restProps
}) => {
  const {
    register,
    formState: { errors },
  } = useFormContext() // retrieve all hook methods

  return (
    <div className="mb-1">
      {label && <label className={cx({ required })}>{label}</label>}
      <textarea
        name={name}
        className={cx(className, 'tw-control', {
          invalid: errors[name]?.message,
        })}
        placeholder={placeholder}
        defaultValue={defaultValue}
        {...register(name, rules)}
        {...restProps}
      />
      {errors[name]?.message && (
        <div className="invalid-msg">{errors[name]?.message}</div>
      )}
    </div>
  )
}

const Input = {
  Text,
  Hidden,
  Number,
  Email,
  Password,
  TextArea,
}
export default Input

import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { FaUser, FaUsersCog } from 'react-icons/fa'

import { businessTypes, beneficiaryType } from 'dataSource/constants'
import { useBusiness } from 'backend/useBusiness'
import { selectBusiness } from 'features/businessSlice'
import { selectUi, uiActions } from 'features/uiSlice'
import { isEmpty } from 'utils/helpers'
import Placeholder from 'Components/Placeholder'

import BeneficiaryIndividual from './BeneficiaryIndividual'
import BeneficiaryCorporate from './BeneficiaryCorporate'
import BeneficiaryFamily from './BeneficiaryFamily'
import BeneficiaryLegacy from './BeneficiaryLegacy'

import BeneficiaryModalSelect from './BeneficiaryModalSelect'
import BeneficiaryModalAdd from './BeneficiaryModalAdd'
import BeneficiaryModalEdit from './BeneficiaryModalEdit'
import BeneficiaryModalRemove from './BeneficiaryModalRemove'

const BeneficiaryDetails = () => {
  const { id } = useParams()
  const dispatch = useDispatch()
  const { reUsableModal } = useSelector(selectUi)
  const [selectedBusiness, setSelectedBusiness] = useState({})
  const [selectedIndex, setSelectedIndex] = useState(0)
  const { business } = useSelector(selectBusiness)

  const { getBusiness } = useBusiness()

  useEffect(() => {
    getBusiness(id).then((item) => {
      let beneficiaryType = item?.beneficiaryType
      let beneficiaries =
        item?.beneficiaries === null ? [] : item?.beneficiaries
      let corporateBeneficiaries =
        item?.corporateBeneficiaries === null
          ? []
          : item?.corporateBeneficiaries
      if (
        item?.businessType === businessTypes.family &&
        item?.beneficiaryType === null
      ) {
        beneficiaryType = beneficiaryType.Individual
      }
      // beneficiaryType = null

      setSelectedBusiness({
        ...item,
        beneficiaryType,
        beneficiaries,
        corporateBeneficiaries,
        // blocked: true,
        // expired: true,
      })
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, business])

  const selectType = () => {
    dispatch(uiActions.openReUsableModal({ key: 'select' }))
  }

  const add = (i) => {
    setSelectedIndex(i)
    dispatch(uiActions.openReUsableModal({ key: 'add' }))
  }

  const edit = (i) => {
    setSelectedIndex(i)
    dispatch(uiActions.openReUsableModal({ key: 'edit' }))
  }

  const remove = (i) => {
    setSelectedIndex(i)
    dispatch(uiActions.openReUsableModal({ key: 'remove' }))
  }

  return (
    <div>
      {/* <pre>{JSON.stringify(selectedBusiness, null, 2)}</pre> */}
      {reUsableModal.key === 'select' && (
        <BeneficiaryModalSelect
          business={selectedBusiness}
          id={selectedBusiness?.id}
        />
      )}
      {reUsableModal.key === 'add' && (
        <BeneficiaryModalAdd
          business={selectedBusiness}
          id={selectedBusiness?.id}
          index={selectedIndex}
        />
      )}
      {reUsableModal.key === 'edit' && (
        <BeneficiaryModalEdit
          business={selectedBusiness}
          id={selectedBusiness?.id}
          index={selectedIndex}
        />
      )}
      {reUsableModal.key === 'remove' && (
        <BeneficiaryModalRemove
          business={selectedBusiness}
          id={selectedBusiness?.id}
          index={selectedIndex}
        />
      )}
      {isEmpty(selectedBusiness) ? (
        <Placeholder
          icon={FaUser}
          text="Your Beneficiaries will display here"
        />
      ) : selectedBusiness?.businessType === businessTypes.family ? (
        <BeneficiaryFamily
          beneficiaries={selectedBusiness?.beneficiaries}
          beneficiaryCount={selectedBusiness?.beneficiaryCount}
          edit={edit}
          add={add}
          remove={remove}
          changeType={<ChangeType selectType={selectType} />}
        />
      ) : selectedBusiness?.businessType === businessTypes.legacy ? (
        <BeneficiaryLegacy
          beneficiaries={selectedBusiness?.beneficiaries}
          beneficiaryCount={selectedBusiness?.beneficiaryCount}
          edit={edit}
          add={add}
          remove={remove}
          changeType={
            <ChangeType
              selectType={selectType}
              title="Change Beneficiaries Count"
            />
          }
        />
      ) : selectedBusiness?.beneficiaryType === beneficiaryType.Corporate ? (
        <BeneficiaryCorporate
          corporateBeneficiaries={selectedBusiness?.corporateBeneficiaries}
          edit={edit}
          add={add}
          remove={remove}
          changeType={<ChangeType selectType={selectType} />}
        />
      ) : selectedBusiness?.beneficiaryType === beneficiaryType.Individual ? (
        <BeneficiaryIndividual
          beneficiaries={selectedBusiness?.beneficiaries}
          beneficiaryCount={selectedBusiness?.beneficiaryCount}
          edit={edit}
          add={add}
          remove={remove}
          changeType={<ChangeType selectType={selectType} />}
        />
      ) : (
        <SelectType selectType={selectType} />
      )}
    </div>
  )
}

export default BeneficiaryDetails

const ChangeType = ({
  selectType = () => {},
  title = 'Change Beneficiary Type',
}) => {
  return Array(1)
    .fill(1)
    .map((n, i) => (
      <div
        className="col-auto bg-black text-sm text-muted p-4 rounded-lg"
        key={i}
      >
        <div className="flex justify-center items-center  h-full p-10">
          <button
            className="m-0 p-0 text-sm text-gold-400"
            onClick={() => selectType()}
          >
            <FaUsersCog className="text-3xl inline-block mb-1" />
            <div>{title}</div>
          </button>
        </div>
      </div>
    ))
}

const SelectType = ({ selectType = () => {} }) => {
  return (
    <div>
      <div>
        <h2 className="text-2xl mb-5">Beneficiary Details</h2>
      </div>
      <div className="grid grid-cols-2 gap-5">
        <div className="bg-black text-sm text-muted p-4 rounded-lg">
          <div className="flex justify-center items-center p-10">
            <button
              className="m-0 p-0 text-sm text-gold-400"
              onClick={() => selectType()}
            >
              <FaUsersCog className="text-3xl inline-block mb-1" />
              <div>Select Beneficiary Type</div>
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

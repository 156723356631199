import axios from 'axios'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { useCookie, useLocalStorage } from 'react-use'
import { toast } from 'react-toastify'
import { showLoader } from '../utils/loader'
import { config } from '../config'
import { paginationAction } from 'features/pagination/paginationSlice'
import { authActions, selectAuth } from 'features/authSlice'

const baseURL = config.apiUrl
const tokenName = process.env.REACT_APP_TOKEN_NAME || 'authToken'

const extractErrorMsg = (error) => {
  console.log('error', error)
  console.log('error', error.message)
  console.log('response', error.response)
  let message = ''
  if (error?.response) {
    if (error?.response?.data) {
      if (error?.response?.data?.message?.message) {
        message += error?.response?.data?.message?.message
      }
      message += error?.response?.data?.message
      // this is for array of error messages
      if (Array.isArray(error?.response?.data?.errors)) {
        message += ': ' + error?.response?.data?.errors[0]
      }
      // this is for array of error messages
      if (Array.isArray(error?.response?.data?.message)) {
        message += error?.response?.data?.message[0]
      }
    }
  } else if (error?.message) {
    message += error?.message
  } else {
    message += error?.response?.statusText || 'Network error'
  }
  return message
}

export const useBackend = () => {
  const auth = useSelector(selectAuth)
  const [tokenC, updateTokenC, deleteTokenC] = useCookie(tokenName)
  const [tokenL, updateTokenL, deleteTokenL] = useLocalStorage(tokenName)
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const backend = (needLoader = false) => {
    const nest = axios.create({ baseURL: baseURL })
    try {
      // Add a request interceptor
      nest.interceptors.request.use(
        function (config) {
          // Do something before request is sent
          if (config.loader) {
            // if (needLoader) {
            showLoader(true)
            // window.showLoader = true
          }
          if (config.msg) {
            if (config.msg.loading) {
              config.msg.id = toast.loading(config.msg.loading)
            }
          }
          if (getToken()) {
            config.headers = { Authorization: `Bearer ${getToken()}` }
          }
          return config
        },
        function (error) {
          // Do something with request error
          return Promise.reject(error)
        }
      )

      // Add a response interceptor
      nest.interceptors.response.use(
        function (response) {
          // Any status code that lie within the range of 2xx cause this function to trigger
          // Do something with response data
          if (response.config.msg) {
            let message =
              response?.config?.msg?.success || response?.data?.message
            if (response?.config?.msg?.id) {
              toast.update(response.config.msg.id, {
                render: message,
                type: 'success',
                isLoading: false,
                autoClose: true,
              })
            } else {
              toast.success(message)
            }
          }
          // if (response?.data?.token) {
          //   storeToken(response?.data?.token)
          // }

          if (response?.config?.loader) {
            // if (needLoader) {
            showLoader(false)
            // window.showLoader = false
          }
          return response?.data || response
        },
        function (error) {
          // Any status codes that falls outside the range of 2xx cause this function to trigger
          // Do something with response error
          let message = extractErrorMsg(error)
          if (error?.response?.config?.msg) {
            if (error?.response?.config?.msg?.id) {
              toast.update(error.response.config.msg.id, {
                render: message,
                type: 'error',
                isLoading: false,
                autoClose: true,
              })
            } else {
              toast.error(message)
            }
          } else if (error?.config?.msg) {
            if (error?.config?.msg?.id) {
              toast.update(error.config.msg.id, {
                render: message,
                type: 'error',
                isLoading: false,
                autoClose: true,
              })
            } else {
              toast.error(message)
            }
          }
          // if (error?.response?.config?.loader) {
          // if (needLoader) {
          // showLoader(false)
          // window.showLoader = false
          // }
          if (
            error?.response?.status === 401 &&
            window.location.href.search('auth') === -1
          ) {
            window.location.href = '/auth/login'
          }
          showLoader(false)
          return Promise.reject(error)
        }
      )
    } catch (error) {
      toast.error(error)
      console.log(error)
    }
    return nest
  }

  const updateToken = (token, remember) => {
    dispatch(authActions.setToken({ token: token }))
    updateTokenL(token)
    remember === true && updateTokenC(token)
  }

  const removeToken = (token) => {
    deleteTokenL(token)
    deleteTokenC(token)
  }

  const getToken = () => {
    return auth.token || tokenL || tokenC || null
  }

  /**
   *
   * @param {Endpoint Name} endpoint
   * @param {Uid} id
   * @returns
   */
  const getItem = (endpoint, id, config = {}) =>
    new Promise((resolve) => {
      // let config = {
      //   loader, msg: { loading: "Registering...", success: "Registered successfully." }
      // }
      backend()
        .get(`/${endpoint}/${id}`, config)
        .then((data) => {
          return resolve(data)
        })
        .catch((error) => {
          console.log(error)
        })
    })

  /**
   *
   * @param {Endpoint Name} endpoint
   * @param {Uid} id
   * @returns
   */
  const trashItem = (endpoint, id, config = {}) =>
    new Promise((resolve) => {
      // let config = {
      //   loader: true, msg: { loading: "Deleting...", success: "Deleted successfully." }
      // }
      backend()
        .delete(`/${endpoint}/${id}`, config)
        .then((data) => {
          dispatch(paginationAction.deleteById({ id }))
          if (config?.redirectTo) {
            navigate(`/${config?.redirectTo}`)
          }
          return resolve(data)
        })
        .catch((error) => {
          console.log(error)
        })
    })

  /**
   *
   * @param {Endpoint Name} endpoint
   * @param {Uid} id
   * @returns
   */
  const deleteItem = (endpoint, id, config = {}) =>
    new Promise((resolve) => {
      // let config = {
      //   loader: true, msg: { loading: "Deleting...", success: "Deleted successfully." }
      // }
      backend()
        .delete(`/${endpoint}/delete/${id}`, config)
        .then((data) => {
          dispatch(paginationAction.deleteById({ id }))
          if (config?.redirectTo) {
            navigate(`/${config?.redirectTo}`)
          }
          return resolve(data)
        })
        .catch((error) => {
          console.log(error)
        })
    })

  const updateItem = (endpoint, id, payload, config) =>
    new Promise((resolve) => {
      backend()
        .put(`/${endpoint}/${id}`, payload, config)
        .then((data) => {
          dispatch(paginationAction.clear())
          if (config?.redirectTo) {
            navigate(`/${config?.redirectTo}`)
          }
          return resolve(data)
        })
        .catch((error) => {
          console.log(error)
        })
    })

  /**
   *
   * @param {Endpoint Name} endpoint
   * @param {Uid} id
   * @returns
   */
  const getList = (endpoint, query = {}, config = {}) =>
    new Promise((resolve) => {
      const defaultQuery = { limit: 50 }
      // let config = {
      //   loader, msg: { loading: "Registering...", success: "Registered successfully." }
      // }
      const params = new URLSearchParams({
        ...defaultQuery,
        ...query,
      }).toString()

      backend()
        .get(`/${endpoint}?${params}`, config)
        .then((data) => {
          return resolve(data)
        })
        .catch((error) => {
          console.log(error)
        })
    })

  /**
   *
   * @param {Endpoint Name} endpoint
   * @param {Uid} id
   * @returns
   */
  const searchItems = (
    endpoint,
    searchBy,
    searchValue,
    otherConditions = {},
    config = {}
  ) =>
    new Promise((resolve) => {
      const defaultQuery = { limit: 50 }
      // let config = {
      //   loader, msg: { loading: "Registering...", success: "Registered successfully." }
      // }
      const params = new URLSearchParams({
        ...defaultQuery,
        [searchBy]: searchValue,
        // [searchBy]: searchValue + "\uf8ff",
        ...otherConditions,
      }).toString()

      backend()
        .get(`/${endpoint}?${params}`, config)
        .then(({ items }) => {
          return resolve(items)
        })
        .catch((error) => {
          console.log(error)
        })
    })

  const addItem = (endpoint, payload, config = {}) =>
    new Promise((resolve) => {
      backend()
        .post(`/${endpoint}`, payload, config)
        .then((data) => {
          dispatch(paginationAction.clear())
          if (config?.redirectTo) {
            navigate(`/${config?.redirectTo}`)
          }
          return resolve(data)
        })
        .catch((error) => {
          console.log(error)
        })
    })

  const uploadFile = (endpoint, identifier, formData, config = {}) =>
    new Promise((resolve, reject) => {
      config.headers = { 'Content-Type': 'multipart/form-data' }
      backend()
        .post(`/${endpoint}/file/${identifier}`, formData, config)
        .then((data) => {
          if (config?.redirectTo) {
            navigate(`/${config?.redirectTo}`)
          }
          return resolve(data)
        })
        .catch((error) => {
          reject(error)
          console.log(error)
        })
    })

  const deleteFile = (endpoint, id, config = {}) =>
    new Promise((resolve) => {
      backend()
        .delete(`/${endpoint}/file/${id}`, config)
        .then((data) => {
          if (config?.redirectTo) {
            navigate(`/${config?.redirectTo}`)
          }
          return resolve(data)
        })
        .catch((error) => {
          console.log(error)
        })
    })

  const uploadS3File = (file, payload, config = {}) =>
    new Promise((resolve) => {
      config.headers = { 'Content-Type': 'multipart/form-data' }
      backend()
        .post(`/s3/upload`, payload, config)
        .then((data) => {
          if (config?.redirectTo) {
            navigate(`/${config?.redirectTo}`)
          }

          return resolve(data)
        })
        .catch((error) => {
          console.log(error)
        })
    })

  const uploadS3FileUrl = (type = '') => {
    return `${baseURL}/s3/upload/${type}`
  }

  const deleteS3File = (key, config = {}) =>
    new Promise((resolve) => {
      backend()
        .delete(`/s3?key=${key}`, config)
        .then((data) => {
          if (config?.redirectTo) {
            navigate(`/${config?.redirectTo}`)
          }
          return resolve(data)
        })
        .catch((error) => {
          console.log(error)
        })
    })

  const s3GetUrl = (key) => {
    return `${config.cdnUrl}/${key}`
  }

  const generatePreSignedURL = async (payload) => {
    const preSignedURLs = []

    for (const { fileName, fileType } of payload.documents) {
      const params = new URLSearchParams({ fileName, fileType }).toString()
      const preSignedURLResponse = await backend().get(
        `/s3/presigned-url?${params}`,
        { msg: true }
      )
      preSignedURLs.push(preSignedURLResponse)
    }

    return preSignedURLs
  }

  const uploadFilesToS3 = async (payload) => {
    const imageBucketURLs = []

    for (const file of payload.files) {
      const { url, fields, keyName, fileData } = file

      const formData = new FormData()
      Object.keys(fields).forEach((key) => formData.append(key, fields[key]))
      formData.append('file', fileData)

      const response = await fetch(url, {
        method: 'POST',
        body: formData,
      })
      if (response.ok) {
        imageBucketURLs.push({
          name: keyName,
          s3Url: `${url}${url.endsWith('/') ? '' : '/'}${fields.key}`,
        })
      }
    }
    return imageBucketURLs
  }

  const createAsset = async (endpoint, payload) => {
    return backend().post(`${endpoint}`, payload, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
  }

  return {
    baseURL,
    uploadS3FileUrl,
    uploadS3File,
    backend,
    getToken,
    updateToken,
    removeToken,

    getItem,
    getList,
    searchItems,
    addItem,
    trashItem,
    updateItem,
    deleteItem,
    uploadFile,
    deleteFile,
    deleteS3File,
    s3GetUrl,
    generatePreSignedURL,
    uploadFilesToS3,
    createAsset,
  }
}

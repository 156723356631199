import { useDispatch, useSelector } from 'react-redux'
import { useBackend } from './useBackend'
import {
  certificateActions,
  selectCertificates,
} from 'features/certificateSlice'

export function useCertificate() {
  const dispatch = useDispatch()
  const { backend, baseURL } = useBackend()
  const { certificates } = useSelector(selectCertificates)

  const getCertificates = () => {
    const params = {
      orderBy: 'title',
      order: 'asc',
      status: 'Active',
      limit: 500,
    }
    const query = new URLSearchParams(params).toString()
    backend()
      .get(`/certificate/?${query}`)
      .then(({ items }) => {
        dispatch(
          certificateActions.loaded({
            items: items,
          })
        )
      })
      .catch((e) => {
        console.error(e.message)
      })
  }

  const getCertificateUrl = (businessId, certificateId, query = {}) => {
    // console.log("baseURL", `${baseURL}/certificate/download/${businessId}/${certificateId}`)
    const params = new URLSearchParams(query).toString()
    return `${baseURL}/certificate/download/${certificateId}/${businessId}?${params}`
  }

  return {
    certificates,
    getCertificates,
    getCertificateUrl,
  }
}

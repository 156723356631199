import React from 'react'
import { format } from 'date-fns'
import { FaEdit, FaUserPlus, FaTrash } from 'react-icons/fa'
import { countryNameByIso2 } from 'dataSource/countries'
import classNames from 'classnames'
import DateTimeAgo from 'Components/DateTimeAgo'

const BeneficiaryLegacy = ({
  beneficiaries = [],
  beneficiaryCount,
  changeType,
  add = () => {},
  edit = () => {},
  remove = () => {},
}) => {
  let beneficiariesLength = 0
  if (beneficiaries) {
    beneficiariesLength = beneficiaries?.length
  }

  return (
    <div>
      <div>
        <h2 className="text-2xl mb-5">Legacy Beneficiary Details</h2>
      </div>
      <div className="grid grid-cols-2 gap-5">
        {(beneficiaries || []).map(
          (item, i) =>
            i + 1 <= beneficiaryCount && (
              <div
                className="col-auto relative block text-xs bg-black rounded-lg p-4"
                key={i}
              >
                <div className="absolute right-3 top-3 flex gap-2">
                  <FaEdit
                    className="text-gold-500 text-lg cursor-pointer"
                    onClick={() => edit(i)}
                  />
                  <FaTrash
                    className="text-gold-500 text-lg cursor-pointer"
                    onClick={() => remove(i)}
                  />
                </div>
                <div className="text-gold-400 mb-3 font-bold">
                  BIRTH CERTIFICATE - BENEFICIARY NO. {i + 1} DETAILS
                </div>
                <div className="space-y-2 odd:*:text-muted odd:*:inline-block odd:*:pr-1 even:*:after:block">
                  <span>Name of Trustee:</span>
                  <b>
                    {item?.firstName} {item?.lastName}
                  </b>
                  <span>Date of Birth:</span>
                  <b>
                    {item?.dob && format(new Date(item?.dob), 'dd/MM/yyyy')}
                  </b>
                  <span>Marital Status:</span>
                  <b>{item?.maritalStatus}</b>
                  <span>Passport/ID Number:</span>
                  <b>{item?.passportNumber}</b>
                  {item?.street && (
                    <>
                      <span>Street:</span>
                      <b>{item?.street}</b>
                    </>
                  )}
                  <span>City:</span>
                  <b>{item?.city}</b>
                  <span>State:</span>
                  <b>{item?.state}</b>
                  <span>Country:</span>
                  <b>{countryNameByIso2(item?.country)}</b>
                  <span>Zip Code:</span>
                  <b>{item?.zipCode}</b>
                  <span>Mobile Number:</span>
                  <b>{item?.mobile}</b>
                  <span>Email:</span>
                  <b>{item?.email}</b>
                  <span>Share:</span>
                  <b>{item?.share}%</b>
                  {item?.updatedAt && (
                    <>
                      <span>Last Updated:</span>
                      <b>
                        <DateTimeAgo date={item?.updatedAt} />
                      </b>
                    </>
                  )}
                </div>
              </div>
            )
        )}
        {beneficiariesLength <= beneficiaryCount &&
          Array(beneficiaryCount - beneficiaries?.length)
            .fill(1)
            .map((n, i) => (
              <div
                className={classNames(
                  'col-auto bg-black text-sm text-muted p-4 rounded-lg',
                  i !== 0 && 'opacity-50 cursor-not-allowed'
                )}
                key={i}
              >
                <div className="flex justify-center items-center h-full p-10">
                  <button
                    className="m-0 p-0 text-sm text-gold-400"
                    onClick={() => add(beneficiariesLength)}
                    disabled={i !== 0}
                  >
                    <FaUserPlus className="text-3xl inline-block mb-1" />
                    <div>
                      Add Birth Certificate
                      <br /> Beneficiary No. {beneficiariesLength + i + 1}{' '}
                      Details
                    </div>
                  </button>
                </div>
              </div>
            ))}
        {changeType}
      </div>
    </div>
  )
}

export default BeneficiaryLegacy

import React from 'react'
import { Link } from 'react-router-dom'

const ExpiredBlock = ({ business, handleClose = () => {} }) => {
  return (
    <div>
      {business?.expired && (
        <>
          <h3 className="text-muted text-xl mb-2">
            Expired business can not be updated.
          </h3>
          <p>
            Please <Link to={`/payment/renew/${business?.id}`}>renew</Link> your
            business.
          </p>
        </>
      )}
      {business?.blocked && (
        <>
          <h3 className="text-muted text-xl mb-2">
            Updating functionalities is disabled for this business.
          </h3>
          <p>
            Your business is blocked by admin. Please{' '}
            <Link to="/contact">contact</Link> support team...
          </p>
        </>
      )}
      <div className="flex justify-end mt-5">
        <button
          type="button"
          className="btn btn-secondary"
          onClick={handleClose}
        >
          Close
        </button>
      </div>
    </div>
  )
}

export default ExpiredBlock

import { useBackend } from 'backend/useBackend'

const useAdminPayment = () => {
  const { backend } = useBackend()

  const confirmAdminPayment = (payload) =>
    new Promise((resolve) => {
      let config = {
        loader: true,
        msg: { loading: 'Processing.' },
      }
      backend()
        .post(`/admin-payment/confirm`, payload, config)
        .then(({ item }) => {
          return resolve(item)
        })
        .catch((e) => console.log(e))
    })

  return {
    confirmAdminPayment,
  }
}

export default useAdminPayment

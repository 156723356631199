import React, { useEffect } from 'react'
// import { Link } from "react-router-dom"
import { useSelector } from 'react-redux'
import { FaBitbucket } from 'react-icons/fa'
import dayjs from 'dayjs'

import { LoaderTextBlock } from 'utils/loader'
import Placeholder from 'Components/Placeholder'
import { usePrice } from 'ECommerce/hooks/usePrice'
import { Link, useParams } from 'react-router-dom'
import { useOrder } from 'backend/useOrder'
import { selectOrders } from 'features/ordersSlice'
import { status } from 'features/status'
// import Badge from "tui/Components/Badge"

const CompanyPaymentHistory = () => {
  const { price } = usePrice()
  const { id } = useParams()
  const { getPaymentsByBusiness } = useOrder()
  const { ordersByBusiness } = useSelector(selectOrders)

  useEffect(() => {
    getPaymentsByBusiness({ id })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div>
      <div>
        <h2 className="text-2xl mb-3">Payment History</h2>
      </div>
      <div className="mt-2">
        <div>
          {ordersByBusiness.status === status.loading ? (
            <LoaderTextBlock />
          ) : ordersByBusiness?.items?.length === 0 ? (
            <Placeholder icon={FaBitbucket} text="Payments will display here" />
          ) : (
            ordersByBusiness.items.map((item) => (
              <div className="bg-black my-4 rounded-md p-2" key={item.id}>
                <div className="font-semibold m-2">
                  <div className="flex justify-between space-x-4">
                    <div className="w-2/4">
                      <Link
                        className="mb-2 text-lg"
                        to={`/orders/${item.id}`}
                        state={item}
                      >
                        Order {item.id}
                      </Link>
                      <p className="mb-1 text-muted">{item.title}</p>
                      <p className=" text-sm text-gold-400">
                        Ordered on{' '}
                        {dayjs(item.createdAt).format('MMM DD, YYYY')}
                      </p>
                    </div>
                    <div className="text-right">
                      <p className="text-muted mb-2">
                        {price(item.amount, item.currency)} — {item.status}
                      </p>
                      <p className="text-sm text-gold-400 mb-2">
                        {item.gateway}
                      </p>
                      {/* <Link to="/">View Details</Link> */}
                      {/* <Badge status={item.status}>{item.status}</Badge> */}
                    </div>
                  </div>
                </div>
              </div>
            ))
          )}
        </div>
      </div>
    </div>
  )
}

export default CompanyPaymentHistory

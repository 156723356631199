import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'

import { Form, Submit } from 'tui'
import { useBusiness } from 'backend/useBusiness'
import { beneficiaryType } from 'dataSource/constants'
import ReusableModal from 'Components/ReusableModal'
import ExpiredBlock from 'Components/ExpiredBlock'
import { uiActions } from 'features/uiSlice'

const BeneficiaryModalRemove = ({
  business,
  id,
  index, // it is important
}) => {
  const dispatch = useDispatch()
  const [item, setItem] = useState(null)
  const { updateBusinessDetails } = useBusiness()

  useEffect(() => {
    if (business) {
      if (business.beneficiaryType === beneficiaryType.Corporate) {
        const tmp = business.corporateBeneficiaries[index]
        setItem({ ...tmp, fullName: tmp.businessName })
      }
      if (business.beneficiaryType === beneficiaryType.Individual) {
        const tmp = business.beneficiaries[index]
        setItem({ ...tmp, fullName: tmp.firstName + ' ' + tmp.lastName })
      }
    }
  }, [business, index])

  const handleClose = () => {
    dispatch(uiActions.closeReUsableModal())
  }

  const onSubmit = (data) => {
    console.log(data)
    let payload = {
      id: id,
    }
    if (business.beneficiaryType === beneficiaryType.Corporate) {
      payload = {
        id: id,
        corporateBeneficiaries: [],
        beneficiaryCount: 1,
      }
    }
    if (business.beneficiaryType === beneficiaryType.Individual) {
      let beneficiaries = [...business.beneficiaries]
      beneficiaries.splice(index, 1)
      payload = {
        id: id,
        beneficiaries: beneficiaries,
      }
    }
    const msg = { loading: 'Removing...', success: 'Beneficiary Removed.' }
    updateBusinessDetails(payload, msg).then(() => {
      handleClose()
    })
  }

  const getTitle = () => {
    if (business?.blocked) {
      return 'Your business is blocked!'
    } else if (business?.expired) {
      return 'Your business is expired!'
    }
    return 'Are you sure?'
  }

  return (
    <ReusableModal title={getTitle()}>
      {/* <pre>{JSON.stringify(shareholder, null, 2)}</pre> */}
      {business?.expired || business?.blocked ? (
        <ExpiredBlock business={business} handleClose={handleClose} />
      ) : (
        <Form onFinish={onSubmit} initialValues={item}>
          {item && (
            <div className="mt-5">
              <h3 className="text-muted text-xl mb-2">
                You want to remove this beneficiary?
              </h3>
              <p>
                {item.fullName} having {item.share}% share
              </p>
            </div>
          )}
          <div className="flex justify-end mt-5">
            {/* <button
            type="button"
            className="mr-2 btn btn-secondary"
            onClick={handleClose}
          >
            Close
          </button> */}
            <Submit className="btn btn-danger">Remove</Submit>
          </div>
        </Form>
      )}
    </ReusableModal>
  )
}

export default BeneficiaryModalRemove

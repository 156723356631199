export const getParameterByName = (name, url = window.location.href) => {
  // eslint-disable-next-line
  name = String(name).replace(/[\[\]]/g, '\\$&')
  var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
    results = regex.exec(url)
  if (!results) return null
  if (!results[2]) return ''
  return decodeURIComponent(results[2].replace(/\+/g, ' '))
}

export const getBaseUrl = (url, level = 1) => {
  const segment = String(url).split('/')
  if (level === 2) {
    return `/${segment[1]}/${segment[2]}`
  } else {
    return `/${segment[1]}`
  }
}

export const makeUrl = (match, endpoint, id = null) => {
  // console.log(match)
  const segments = String(match.url).replace(/\/$/, '')
  // console.log(segments)
  if (id) {
    return `${segments}/${endpoint}/${id}`
  }
  return `${segments}/${endpoint}`
}

export const convertToSlug = (str) => {
  return String(str)
    .toLowerCase()
    .replace(/^\s+|\s+$/g, '') // trim
    .replace(/[^a-z0-9 -]/g, '') // remove invalid chars
    .replace(/\s+/g, '-') // collapse whitespace and replace by -
    .replace(/-+/g, '-') // collapse dashes
  // .replace(/ /g, '-')
  // .replace(/[^\w-]+/g, '')
}

export const checkForDuplicateTab = () => {
  var currentUrl = window.location.href
  var tabs = window.opener ? window.opener.window.frames : window.frames
  for (var i = 0; i < tabs.length; i++) {
    try {
      var tabUrl = tabs[i].location.href
      if (tabUrl === currentUrl) {
        tabs[i].focus()
        window.close()
        return true
      }
    } catch (e) {}
  }
  return false
}

// import { lazy } from 'react';

// project imports
import PaymentLayout from 'Layouts/PaymentLayout'
import AuthGuard from './AuthGuard'
import PaymentOptionAgent from 'ECommerce/PaymentPages/PaymentOptionAgent'
import PaymentOptionRegister from 'ECommerce/PaymentPages/PaymentOptionRegister'
import PaymentOptionRenew from 'ECommerce/PaymentPages/PaymentOptionRenew'
import PaymentOptionGoodStanding from 'ECommerce/PaymentPages/PaymentOptionGoodStanding'
import PaymentOptionAddendum from 'ECommerce/PaymentPages/PaymentOptionAddendum'

// payment gateways page
import AdminPayment from 'ECommerce/PaymentGateways/AdminPayment'
// import PayPal from 'ECommerce/PaymentGateways/PayPal'
import SquareUp from 'ECommerce/PaymentGateways/SquareUp'

import BlankLayout from 'Layouts/BlankLayout'
import OrderPlaced from 'Components/OrderPlaced'

// const Page404 = lazy(() => import('Pages/404'));

// ==============================|| PAGES ROUTING ||============================== //

const PaymentRoutes = {
  path: '/',
  children: [
    {
      path: 'payment',
      element: (
        <AuthGuard>
          <PaymentLayout />
        </AuthGuard>
      ),
      children: [
        {
          path: 'account/:sub',
          element: <PaymentOptionAgent />,
        },
        {
          path: 'register/:id',
          element: <PaymentOptionRegister />,
        },
        {
          path: 'renew/:id',
          element: <PaymentOptionRenew />,
        },
        {
          path: 'addendum/:id',
          element: <PaymentOptionAddendum />,
        },
        {
          path: 'good-standing/:companyId',
          element: <PaymentOptionGoodStanding />,
        },
      ],
    },
    {
      path: 'payment',
      element: (
        <AuthGuard>
          <BlankLayout />
        </AuthGuard>
      ),
      children: [
        {
          path: 'admin-payment/:orderId',
          element: <AdminPayment />,
        },
        // {
        //   path: 'paypal/:orderId',
        //   element: <PayPal />,
        // },
        {
          path: 'square/:orderId',
          // path: 'square',
          element: <SquareUp />,
        },
        // {
        //   path: 'forumpay/:orderId',
        //   element: <StepPaymentForumPay />
        // },
        // {
        //   path: 'onramper/:orderId',
        //   element: <OnRamperWidget />
        // }
        {
          path: 'done/:orderId',
          element: <OrderPlaced />,
        },
      ],
    },
  ],
}

export default PaymentRoutes

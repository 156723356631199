import React, { Fragment, useState } from 'react'
import { Link } from 'react-router-dom'
import InfiniteScroll from 'react-infinite-scroller'
import { FaBitbucket, FaEdit, FaPlus, FaTrashAlt } from 'react-icons/fa'
import { HiCog, HiRefresh } from 'react-icons/hi'

import { Form, Input, Select, Submit } from 'tui'
import Placeholder from 'Components/Placeholder'
import DateTimeAgo from 'Components/DateTimeAgo'
import SavedDetailsByType from 'Components/SavedDetailsByType'
import { usePaginate } from 'backend/usePaginate'
import { useBeneficiary } from 'backend/useBeneficiary'
import { BeneficiaryTypes } from 'dataSource/options'
import { beneficiaryType } from 'dataSource/constants'

const endpoint = 'beneficiary-business/my-beneficiaries'

const Beneficiaries = () => {
  const { deleteBeneficiary } = useBeneficiary()
  const [filtered, setFiltered] = useState(false)
  const { hasMore, items, loadMore, filter, loading } = usePaginate(endpoint, {
    limit: 100,
  })

  const onSubmit = (data) => {
    if (data.beneficiaryType === beneficiaryType.Individual) {
      data.firstName = data.q + '%'
    }
    if (data.beneficiaryType === beneficiaryType.Corporate) {
      data.businessName = data.q + '%'
    }
    delete data.beneficiaryType
    delete data.q
    filter(data).then(() => setFiltered(true))
    console.log('data', data)
  }

  const clearFilter = () => {
    filter({}).then(() => setFiltered(false))
  }

  const deleteConfirm = (id) => {
    const input = window.confirm(
      'Are you sure you want to delete this beneficiary?'
    )
    console.log('data', id)
    if (input) {
      deleteBeneficiary(id)
    }
  }

  return (
    <div>
      <h1 className="text-3xl mb-4">Beneficiaries</h1>
      <Form onFinish={onSubmit}>
        <div className="flex justify-between items-start">
          <div className="flex space-x-4">
            <div>
              <Input.Text
                // name="firstName"
                // name="businessName"
                name="q"
                placeholder="Search Beneficiary By Name/Business Name"
              />
            </div>
            <div>
              <Select
                name="beneficiaryType"
                // label="Entity Ending"
                placeholder="Select Beneficiary Type"
                options={BeneficiaryTypes}
                defaultValue={beneficiaryType.Individual}
              />
            </div>
          </div>
          <div className="flex items-center">
            <Submit className="bg-primary flex items-center p-2 px-4 mr-2">
              <HiCog className="mr-2" /> Apply Filter
            </Submit>
            {filtered && (
              <button
                type="reset"
                className="flex items-center bg-input p-2 px-4"
                onClick={clearFilter}
              >
                <HiRefresh className="mr-2" />
                Clear Filter
              </button>
            )}
          </div>
        </div>
      </Form>
      <div className="mt-2">
        <div className="flex justify-end pt-2 mx-4">
          <Link to="/beneficiaries/new" className="flex items-center p-2">
            <FaPlus className="mr-2" /> Add New
          </Link>
        </div>
        {/* <pre>{JSON.stringify(items, null, 2)}</pre> */}
        <div>
          <InfiniteScroll
            pageStart={0}
            initialLoad={false}
            loadMore={loadMore}
            hasMore={hasMore}
            loader={
              loading && (
                <h4 style={{ textAlign: 'center' }} key="loader">
                  Loading...
                </h4>
              )
            }
          >
            <>
              {loading === false && items?.length === 0 ? (
                <Placeholder
                  icon={FaBitbucket}
                  text={
                    filtered
                      ? 'No result found'
                      : 'Shareholders will display here'
                  }
                />
              ) : (
                items.map((item) => (
                  <Fragment key={item.id}>
                    <div className="bg-black my-4 rounded-md p-4">
                      <div className="m-2">
                        <div className="flex justify-between space-x-4">
                          <div className="sm:w-2/4">
                            <p className="text-gold-400 font-bold mb-3">
                              {item.type} Beneficiary
                            </p>
                            <SavedDetailsByType shareholder={item} />
                          </div>
                          <div className="flex flex-col justify-between">
                            <div className="flex space-x-2 justify-end">
                              <Link to={`/beneficiaries/${item.id}`}>
                                <FaEdit title="Edit Beneficiary" />
                              </Link>
                              <button
                                className="p-0 text-gold-400"
                                onClick={() => deleteConfirm(item.id)}
                              >
                                <FaTrashAlt title="Delete Beneficiary" />
                              </button>
                            </div>
                            <p className="text-sm text-muted">
                              Created <DateTimeAgo date={item.createdAt} />
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Fragment>
                ))
              )}
            </>
          </InfiniteScroll>
        </div>
      </div>
    </div>
  )
}

export default Beneficiaries

import React from 'react'
import { useNavigate } from 'react-router-dom'
// import { Link } from 'react-router-dom'
import { useAuth } from 'backend/useAuth'
import { Form, Input, Submit } from 'tui'
import { useSelector } from 'react-redux'
import { selectAuth } from 'features/authSlice'
import { siteModule } from 'config'
import { paymentStatus, siteModules } from 'dataSource/constants'

const VerifyEmail = () => {
  const auth = useSelector(selectAuth)
  const navigate = useNavigate()
  const { verifyEmail } = useAuth()
  const onSubmit = (data) => {
    verifyEmail(data).then(() => {
      setTimeout(() => {
        if (
          siteModule === siteModules.Reseller &&
          auth?.user?.agentStatus !== paymentStatus.Confirmed
        ) {
          navigate('/payment/account/new')
        } else {
          navigate('/')
        }
      }, 500)
    })
  }

  return (
    <div className="md:w-1/3 bg-dark-900 mx-auto p-8 flex flex-col justify-center">
      <div className="text-center">
        <h2 className="text-4xl mb-3">Verify Email</h2>
        <p className="text-muted text-sm">
          Please enter verification code you will receive in email.
        </p>
      </div>
      <Form onFinish={onSubmit}>
        <div className="mt-8">
          <Input.Text
            name="verificationCode"
            label="Verification Code"
            placeholder="Enter Verification Code"
            rules={{ required: 'Verification Code is required' }}
          />
          <div className="flex justify-between items-center mt-5">
            <Submit className="btn-primary py-2">Verify</Submit>
            {/* <span>
              Back to <Link to="/auth/login">Login</Link>
            </span> */}
            {/* <Link to="/">Back to Login</Link> */}
          </div>
        </div>
      </Form>
    </div>
  )
}

export default VerifyEmail

import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import dayjs from 'dayjs'
import InfiniteScroll from 'react-infinite-scroller'

import { HiCog, HiRefresh } from 'react-icons/hi'
import { FaBuilding } from 'react-icons/fa'

import { Form, Input, Submit } from 'tui'
import Placeholder from 'Components/Placeholder'
import { usePaginate } from 'backend/usePaginate'
import { BACKEND_ENDPOINTS } from 'utils/constants'

const Assets = () => {
  const [filtered, setFiltered] = useState(false)
  const { hasMore, items, loadMore, filter, loading } = usePaginate(
    BACKEND_ENDPOINTS.userAssets,
    {
      limit: 100,
    }
  )

  const onSubmit = (data) => {
    filter({ ...data, name: data.name + '%' }).then(() => setFiltered(true))
  }

  const clearFilter = () => {
    filter({}).then(() => setFiltered(false))
  }

  return (
    <div>
      <h1 className="text-3xl mb-4">Assets</h1>
      <Form onFinish={onSubmit}>
        <div className="md:flex justify-between items-start">
          <div className="flex space-x-4">
            <div>
              <Input.Text name="name" placeholder="Search by Asset Name" />
            </div>
          </div>
          <div className="flex items-center">
            <Submit className="bg-primary flex items-center p-2 px-4 mr-2">
              <HiCog className="mr-2" />
              <span>Search</span>
            </Submit>
            {filtered && (
              <button
                type="reset"
                className="flex items-center bg-input p-2 px-4"
                onClick={clearFilter}
              >
                <HiRefresh className="mr-2" />
                Clear Filter
              </button>
            )}
          </div>
        </div>
      </Form>
      <div className="mt-2">
        <div>
          <InfiniteScroll
            pageStart={0}
            initialLoad={false}
            loadMore={loadMore}
            hasMore={hasMore}
            loader={
              loading && (
                <h4 style={{ textAlign: 'center' }} key="loader">
                  Loading...
                </h4>
              )
            }
          >
            <>
              {!loading && !items?.length ? (
                <Placeholder
                  icon={FaBuilding}
                  text={
                    filtered
                      ? 'No result found'
                      : 'Your Assets will display here'
                  }
                />
              ) : (
                items?.map((item) => (
                  <div className="bg-black my-4 rounded-md p-2" key={item.id}>
                    <p className="mt-2 mx-2">
                      <Link to={`/assets/${item.id}`} state={item}>
                        {item.name}
                      </Link>
                      <small className="pl-2 text-muted text-xs">
                        {item.category}
                      </small>
                    </p>
                    <div className="font-semibold mx-2">
                      <div className="flex justify-between space-x-4 items-center">
                        <div className="w-2/4">
                          <p className="text-muted">
                            Formed on{' '}
                            {dayjs(item.createdAt).format('MMM DD, YYYY')}
                          </p>
                          <p className="mt-2 text-xs text-gold-400">
                            {item.bicraFormatted}
                          </p>
                        </div>
                        <p className="text-muted hidden md:block">
                          Quantity: {item.quantity}
                        </p>
                      </div>
                    </div>
                  </div>
                ))
              )}
            </>
          </InfiniteScroll>
        </div>
      </div>
    </div>
  )
}

export default Assets

import { lazy } from 'react'

// project imports
import AuthGuard from './AuthGuard'
import PageLayout from 'Layouts/PageLayout'
import SiteModuleGuard from './SiteModuleGuard'

// For Blockchain Bank
const Intro = lazy(() => import('Pages/Registration/Bank/Step1Intro'))
const BusinessName = lazy(
  () => import('Pages/Registration/Bank/Step2BusinessName')
)
const BusinessDetails = lazy(
  () => import('Pages/Registration/Bank/Step3BusinessDetails')
)
const ShareholderType = lazy(
  () => import('Pages/Registration/Bank/Step4ShareholderType')
)
const ShareHolderCorporate = lazy(
  () => import('Pages/Registration/Bank/Step5ShareHolderCorporate')
)
const ShareHolderIndividualOne = lazy(
  () => import('Pages/Registration/Bank/Step5ShareHolderIndividualOne')
)
const ShareHolderIndividualTwo = lazy(
  () => import('Pages/Registration/Bank/Step5ShareHolderIndividualTwo')
)
const ConfirmDetails = lazy(
  () => import('Pages/Registration/Bank/Step6ConfirmDetails')
)
const UploadDocument = lazy(
  () => import('Pages/Registration/Bank/Step7UploadDocument')
)

// ==============================|| PAGES ROUTING ||============================== //

const RegistrationBankRoutes = {
  path: '',
  children: [
    {
      path: '/register',
      exact: true,
      element: (
        <AuthGuard>
          <PageLayout />
        </AuthGuard>
      ),
      children: [
        {
          path: 'bank',
          element: <Intro />,
        },
      ],
    },
    {
      path: '/register',
      element: (
        <AuthGuard>
          <SiteModuleGuard>
            <PageLayout />
          </SiteModuleGuard>
        </AuthGuard>
      ),
      children: [
        {
          path: 'bank',
          children: [
            // { index: true, element: <Intro /> },
            { path: 'name', element: <BusinessName /> },
            { path: ':id/business', element: <BusinessDetails /> },
            { path: ':id/shareholder', element: <ShareholderType /> },
            {
              path: ':id/shareholder-corporate',
              element: <ShareHolderCorporate />,
            },
            // { path: ':id/shareholder/:holder', element: <ShareHolderIndividuals /> },
            {
              path: ':id/shareholder-1',
              element: <ShareHolderIndividualOne />,
            },
            {
              path: ':id/shareholder-2',
              element: <ShareHolderIndividualTwo />,
            },
            { path: ':id/confirm', element: <ConfirmDetails /> },
            { path: ':id/upload', element: <UploadDocument /> },
            // { path: ':id/payment', element: <StepPayment /> },
          ],
        },
      ],
    },
  ],
}

export default RegistrationBankRoutes

import { lazy } from 'react'

// project imports
import AuthGuard from './AuthGuard'
import PageLayout from 'Layouts/PageLayout'
import SiteModuleGuard from './SiteModuleGuard'

// For Blockchain Family
const Intro = lazy(() => import('Pages/Registration/Family/Step1Intro'))
const BusinessName = lazy(
  () => import('Pages/Registration/Family/Step2BusinessName')
)
const BusinessDetails = lazy(
  () => import('Pages/Registration/Family/Step3BusinessDetails')
)
// const ShareholderType = lazy(() =>
//   import('Pages/RegistrationFamily/Step4ShareholderType')
// )
// const ShareHolderCorporate = lazy(() =>
//   import('Pages/RegistrationFamily/Step5ShareHolderCorporate')
// )
const ShareHolderIndividualOne = lazy(
  () => import('Pages/Registration/Family/Step5ShareHolderIndividualOne')
)
const ShareHolderIndividualTwo = lazy(
  () => import('Pages/Registration/Family/Step5ShareHolderIndividualTwo')
)
const ConfirmDetails = lazy(
  () => import('Pages/Registration/Family/Step6ConfirmDetails')
)
const UploadDocument = lazy(
  () => import('Pages/Registration/Family/Step7UploadDocument')
)
const UploadDocumentOne = lazy(
  () => import('Pages/Registration/Family/Step7UploadDocument')
)
const UploadDocumentFamilyTwo = lazy(
  () => import('Pages/Registration/Family/Step7UploadDocument2')
)
const BeneficiaryListOneForm = lazy(
  () => import('Pages/Registration/Family/Step5BeneficiaryOne')
)
const BeneficiaryListTwoForm = lazy(
  () => import('Pages/Registration/Family/Step5BeneficiaryTwo')
)

// ==============================|| PAGES ROUTING ||============================== //

const RegistrationFamilyRoutes = {
  path: '',
  children: [
    {
      path: '/register',
      exact: true,
      element: (
        <AuthGuard>
          <PageLayout />
        </AuthGuard>
      ),
      children: [
        {
          path: 'family',
          element: <Intro />,
        },
      ],
    },
    {
      path: '/register',
      element: (
        <AuthGuard>
          <SiteModuleGuard>
            <PageLayout />
          </SiteModuleGuard>
        </AuthGuard>
      ),
      children: [
        {
          path: 'family',
          children: [
            // { index: true, element: <Intro /> },
            { path: 'name', element: <BusinessName /> },
            { path: ':id/business', element: <BusinessDetails /> },
            // { path: ':id/shareholder', element: <ShareholderType /> },
            // { path: ':id/shareholder-corporate', element: <ShareHolderCorporate /> },
            // { path: ':id/shareholder/:holder', element: <ShareHolderIndividuals /> },
            {
              path: ':id/shareholder-1',
              element: <ShareHolderIndividualOne />,
            },
            {
              path: ':id/shareholder-2',
              element: <ShareHolderIndividualTwo />,
            },
            {
              path: ':id/beneficiary-1',
              element: <BeneficiaryListOneForm />,
            },
            {
              path: ':id/beneficiary-2',
              element: <BeneficiaryListTwoForm />,
            },
            { path: ':id/passport-1', element: <UploadDocumentOne /> },
            { path: ':id/passport-2', element: <UploadDocumentFamilyTwo /> },
            { path: ':id/:formName/confirm', element: <ConfirmDetails /> },
            { path: ':id/upload', element: <UploadDocument /> },
            // { path: ':id/payment', element: <StepPayment /> },
          ],
        },
      ],
    },
  ],
}

export default RegistrationFamilyRoutes

import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { usePrice } from 'ECommerce/hooks/usePrice'
import { useOrder } from 'backend/useOrder'
import { orderTypes } from 'dataSource/constants'
import { selectECommerce } from 'features/eCommerceSlice'
import { useECommerce } from 'ECommerce/hooks/useECommerce'
import Checkout from 'ECommerce/PaymentPages/Checkout'
import { siteModule } from 'config'
import { selectOrders } from 'features/ordersSlice'

const PaymentOptionGoodStanding = () => {
  const navigate = useNavigate()
  const { paymentMethod, products, product, checkout, coupon } =
    useSelector(selectECommerce)
  const { selectProduct } = useECommerce()
  const { price } = usePrice()
  const {
    orderGoodStanding,
    coinbaseCreateCharge,
    stripeCreateCheckoutSession,
  } = useOrder()
  const { goodStanding } = useSelector(selectOrders)

  useEffect(() => {
    if (products?.items.length > 0) {
      selectProduct(`${siteModule}-good-standing`)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [products])

  useEffect(() => {
    if (!goodStanding?.id) {
      // toast.info('You do not have a certificate of good standing')
      navigate('/')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [goodStanding])

  const initiateOrder = () => {
    orderGoodStanding({
      title: product.name + ' - ' + goodStanding.title,
      items: [
        {
          refId: goodStanding?.id,
          amount: product.payableAmount,
          name: goodStanding.title,
          title: product.name,
          description: product.description,
          quantity: 1,
        },
      ],
      type: orderTypes.GoodStanding,
      gateway: paymentMethod,
      amount: checkout.total,
      currency: 'USD',
      coupon: coupon,
    }).then((order) => {
      if (paymentMethod === 'AdminPayment') {
        console.log(order)
        navigate(`/payment/admin-payment/${order.id}`)
        return
      } else if (paymentMethod === 'Square') {
        console.log(order)
        navigate(`/payment/square/${order.id}`)
        return
        // } else if (paymentMethod === 'Paypal') {
        //   console.log(order)
        //   navigate(`/payment/paypal/${order.id}`)
        //   return
      } else if (paymentMethod === 'CoinBase') {
        coinbaseCreateCharge({
          name: goodStanding.title,
          description: product.description,
          items: [
            {
              amount: checkout.total,
              name: product.name,
              description: product.description,
              quantity: 1,
            },
          ],
          orderId: order.goodStanding?.id,
          currency: 'USD',
        }).then((charge) => {
          window.location.href = charge.url
        })
      }
      // else if (paymentMethod === "OnRamper") {
      //   console.log(order)
      //   navigate(`/payment/onramper/${order.goodStanding?.id}`)
      //   return
      // }
      else if (paymentMethod === 'Stripe') {
        stripeCreateCheckoutSession({
          title: product.name,
          items: [
            {
              amount: checkout.total,
              name: product.name,
              description: product.description,
              quantity: 1,
            },
          ],
          orderId: order.goodStanding?.id,
          currency: 'USD',
        }).then((session) => {
          window.location.href = session.url
        })
      }
      // else if (paymentMethod === 'ForumPay') {
      //   navigate('/payment/forumpay/' + order.goodStanding?.id)
      // }
      else {
        navigate('/payment/done/' + order.id)
      }
    })
  }

  return (
    <div>
      {/* <pre>{JSON.stringify(coupon, null, 2)}</pre> */}
      <div className="text-xs">
        <span className="text-muted">Make Payment For</span>
        <span className="dot">
          {product.name} - {goodStanding.title}
        </span>
      </div>
      <div
        className="mt-5"
        dangerouslySetInnerHTML={{
          __html: String(product.paymentDescription).replace(
            '{price}',
            price(product.payableAmount)
          ),
        }}
      />
      {/* <div className="text-center mt-5">
        <h2 className="text-xl mb-3">Payment</h2>
        <p className="text-muted mb-3 pb-4">
          After successful payment of <b>{price(product.payableAmount)}</b> you
          will receive your Certificate of Good Standing on your provided email.
          <br />Any questions, feel free to send us an email:{' '}
          <a href="mailto:register@blockchaintrust.pro">
            register@blockchaintrust.pro
          </a>
        </p>
      </div> */}
      <div>
        <Checkout
          initiateOrder={initiateOrder}
          items={[
            {
              name: goodStanding.title,
            },
          ]}
        />
      </div>
      <div className="flex justify-between mt-10">
        <button
          type="button"
          className="btn-back"
          tabIndex="-1"
          onClick={() => navigate(-1)}
        >
          Back
        </button>
      </div>
    </div>
  )
}

export default PaymentOptionGoodStanding
